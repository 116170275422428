[data-a11y-toggle]:not([aria-controls]),
[aria-hidden="true"] {
  display: none;
}

/**
 * 1. Globally hiding elements with [aria-hidden="true"] can have rendering
 *    issues with third party integrations. Only known to date is with Google
 *    Maps, in which it prevents the map tiles to render. Therefore it needs to
 *    be resetted inside a Google Maps container.
 *    See: https://github.com/edenspiekermann/a11y-toggle/issues/30
 * 2. Fallback value in case the `initial` value is not supported.
 *    See: http://caniuse.com/#feat=css-initial-value
 */
.map,
.collapsible,
.checks-list,
.results {
  [aria-hidden="true"] {
    /* 1 */
    display: block; /* 2 */
    display: initial;
  }
}

// video embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin-bottom: $spacing;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.box-divider {
  border-bottom: 1px solid $gray2;
  padding-bottom: $spacing;
}

// dificultat
.icon-off {
  fill: lightgray;
  fill: rgba(#000, 0.1);
}

.ruta-pict {
  margin-top: 0;
  @media #{$medium-up} {
    display: none;
  }
}
.wrapper-image{
  width: 100%;
  height: 100%;
  min-height: 80vh;
  margin:-5rem 0;
  padding: 6em 0;
  background-repeat: no-repeat;
  background-size: cover;
}
.box-card {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 0 12px 0 rgba(0,0,0,0.1), 0 10px 30px 0 rgba(0,0,0,0.2);
    max-width: 30.5em;
    margin: 0 auto 3em;
    &.b{
      margin-top: 5em;
    }
    .content{
      padding: 2em;

      h2{
        font-size: rem-calc(34);
        color: $secondary-color;
        margin-bottom: 1rem;
      }
      p{
        font-size: rem-calc(20);
        margin-bottom: 1rem;
      }
      label{
        margin-top: 1rem;
         margin-bottom: .3rem;
      }
      .last-action{
        padding-top: 2rem;
        margin:2rem 0 .15rem;
        font-size: rem-calc(18);
        border-top: solid 1px #ccc;
      }
      .btn{
          text-transform: none;
          margin-top: 1.5rem;
        }
    }
  }

.box-line{
  border-bottom:solid 6px $primary-color;
   border-top:solid 6px $primary-color;
   padding: 1em 0;
   h2{
    font-size: rem-calc(28);

   }
   p{
    font-size:  rem-calc(18);
    margin-top: .5em;
   }
}

.box-icon{
  .icon-wrapper{
      background-color: lighten($secondary-color,42%);
      border-radius: 50%;
      width: 100px;
      height: 100px;
      position: relative;
      margin-bottom: 1rem;
      &.ok{
        background-color: #12A73C;
      }
      svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform:translate(-50%,-50%);
        &.min{
          margin-top: 1.5rem;
        }
      }

    }
}
.invoice-box{
    max-width:800px;
    margin:0 auto 3em;
    padding:30px;
    border:1px solid #eee;
    box-shadow:0 0 10px rgba(0, 0, 0, .15);
    font-size:16px;
    line-height:24px;

    color:#555;

}
.product-card {
    background-color: #fff;
    margin-top: rem-calc(30);
    -webkit-box-shadow: 0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3);
    box-shadow: 0 6px 12px -2px rgba(50,50,93,.25), 0 3px 7px -3px rgba(0,0,0,.3);
    border-radius: 4px;
    padding: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: left;
    .content{
      -webkit-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      .split {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          @media #{$medium-up} {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-align: stretch;
            -ms-flex-align: stretch;
            align-items: stretch;
          }
      }
      .title {
        h3{
          font-size: rem-calc(25);
          color: $secondary-color;
        }
        @media #{$medium-up} {
           width: 30%;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          padding: 16px 0;
        }
      }
      .description {
        position: relative;
        padding: 16px 0;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        p{
          font-weight: 300;
          font-size: rem-calc(18);
        }
        p + p{
          margin-top: 1.5rem;
        }
        &:before {
            content: "";
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            top: -3px;
            pointer-events: none;
            background-color: #e6ebf1;
        }
        @media #{$medium-up} {
          padding: 16px 0 16px 28px;
          margin: 0 0 0 20px;
          width: 70%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: top;
          -ms-flex-pack: top;
          justify-content: top;
          &:before {
              width: 1px;
              height: 100%;
              top: 0;
          }
        }
      }
    }
}
.text-formatted{
  &.mt{
    margin-top: 3rem;
  }
  h2 + p{
    margin-top: .5rem;
  }
  p{
    font-size:rem-calc(18);
  }
  @media #{$medium-up} {
    padding: 0 1rem 1rem;
  }
}
.alert-box{
  margin: 2em 0;
  border: 1px $secondary-color solid;
  padding-bottom: 1em;
  @media #{$medium-up} {
     margin: 2em;
  }
  .header{
    text-align: center;
    background-color: $secondary-color;

    padding: 1em 0;
    color: #fff;
    margin-bottom: 1em;
    p{
      max-width: 550px;
      margin: .5em auto 0;
      @media #{$medium-up} {
         font-size: rem-calc(20);
      }
    }
    .icon-wrapper{
      margin: 0 auto;
      height: 64px;
      width: 64px;
      background-color: $primary-color;
    }
  }
  p{
    font-size: rem-calc(18);
     padding:0 2em;
  }
}
.all-results{

    li{
      .box{
        margin:1em 0;
        background-color: #fff;
        box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
        .results__data{
          padding: 1rem;

          dt:first-of-type {
              display: block;
          }
        }
        @media #{$medium-up} {
           min-height: 675px;
        }
        .btn{
          margin-bottom: 2.5rem;
        }
        @include single-transition();
        .box__title{
            @include single-transition();
        }
        &:hover{
          box-shadow: 0 30px 60px -12px rgba(50,50,93,.15), 0 18px 36px -18px rgba(0,0,0,.1), 0 -12px 36px -8px rgba(0,0,0,.025);
          .box__title{
            background-color: $primary-color;
          }
          .btn{
            color: $primary-color;
            text-decoration: none;
          }
        }
      }
    }

}
p.btn{
  background-color: transparent;
  border:none;
  text-align: initial;
  padding: 0;
  a{
    color:#fff;
    background-color: $primary-color;
    border: 1px solid $primary-color;
    border-radius: 4px;
    padding: .875rem 2.25rem .75rem;
    text-decoration: none;
    text-transform: none;
    display: inline-block;
  }
}
.icon-wrapper{
  background-color: $primary-color;
  padding: $spacing;
  width: 52px;
  height: 52px;
  margin: 0 auto;
  border-radius: 50%;
  position: relative;
  margin-bottom: $spacing*0.5;
  svg{
    position: absolute;
    top:50%;
    left: 50%;
    color:#fff;
    transform:translate(-50%,-50%);
  }
}
.icons{
  margin-bottom: $spacing*1.5;
  h2{
    margin-bottom: $spacing*0.5;
  }
  p{
    font-size:rem-calc(20);
  }
}
.message{
  padding: 0 $spacing*2;
  h2{
    font-size:rem-calc(28);
  }
  p{
    font-size:rem-calc(20);
  }
}
.notice{
  .section{
    padding-bottom: 0;overflow: hidden;
  }
  h1{
    font-size:rem-calc(46);
    @media #{$medium-up} {
      font-size:rem-calc(66);
    }
  }
  .grafic{
    position: relative;
    width: 100%;
    min-height:200px;
    @media #{$medium-up} {
      min-height:300px;
    }
    .bg{
      position: absolute;
      left:0;
      width: 100%;
      bottom:-50px;
      height: 200px;
      @media #{$medium-up} {
           height: 400px;
           bottom:-120px;
        }
    }
    .bicis{
      position: absolute;
      bottom:20%;
      left:30%;
      z-index:2;
      width: 130px;
      @media #{$medium-up} {
         width: 200px;
         bottom:-120px;
         left:40%;
         bottom:25%;
      }
    }
    .senyal{
      position: absolute;
      left: 65%;
      bottom: 0;
      height: 200px;
      transform: rotate3d(1, 1, 1, 45deg);
      @media #{$medium-up} {
          height: 400px;
          left: 75%;
        }
    }
  }
  .text{
    h2{
      font-size: rem-calc(26);
    }
    background: lighten(#529FE8,35%);
    padding: $spacing;
    color: $body-font-color;
    border-radius: 8px;
    p{
      font-size: rem-calc(18);
    }
    a{
      color: $body-font-color;
    }
    box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
  }
  &.newsletter-page{
    .text{
      margin: $spacing*3 0 $spacing*4;
      background: $secondary-color;
      position: relative;
      padding-top: 50px;
      padding-bottom: 50px;

      color:#fff;

    }
    .intro{
      font-size: rem-calc(20);
      text-align: left;
      padding-bottom: 1.5rem;
      max-width: 700px;
      @media #{$medium-up} {
        font-size: rem-calc(30);
        padding: 0 1.5rem 1.5rem;
      }
    }
    .icon{
      background: $secondary-color;
      border-radius:50%;
      position:absolute;
      top: 0;
      left: 50%;
      padding: $spacing;
      transform: translate(-50%, -50%);
    }
    .section{
      form{
        padding: 0;
        @media #{$medium-up} {
          padding: 0 $spacing;
        }
      }
    }
  }


}

.mapaweb{
   box-shadow: 0 30px 60px -12px rgba(50,50,93,.25), 0 18px 36px -18px rgba(0,0,0,.3), 0 -12px 36px -8px rgba(0,0,0,.025);
   text-align: left;
   margin-bottom: $spacing*3;
   padding:$spacing*1.5 $spacing*0.5;
   background-color:#fff;
   @media #{$medium-up} {
    padding: $spacing*1.5;
   }
   ul{
    li{
      padding-left: 20px;
      a{
        color: $body-font-color;
        text-decoration: none;
        @media #{$medium-up} {
          font-size:rem-calc(20);
         }
      }
      &:before{
        top: .45em;
        height: 10px;
        width: 10px;
        border-radius:50%;
        @media #{$medium-up} {
          top: .75em;
        }
      }
    }
   }
}

.rel{
  border-top: 2px solid $secondary-color;
  padding-top: $spacing;
  h2{
    font-size: rem-calc(18);
  }
  ul{
    li{
      margin-left: 0;
      &:before{
        background-color: $secondary-color;
      }
      a{
        position: relative;
        padding-right: rem-calc(28);
        color: $secondary-color;
        svg{
          position: absolute;
          right: 0;
          top:2px;
          fill: $secondary-color;
        }
      }
    }
  }
}
@media #{$small-only} {
  .mobile-mt1{
    margin-top: $spacing!important;
  }
  .mobile-mt2{
    margin-top: $spacing*2!important;
  }
}

#ui-datepicker-div {
  z-index: 25 !important;
}