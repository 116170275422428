.tabbed {
  display: none;
  @media #{$medium-up} {
    display: block;
  }
}
.tab-menu-container {
  background-image: linear-gradient(to bottom, #ffffff, #d8d8d8);
}
.tab-menu {
  padding: $spacing * 2 0 0 0;
  margin-left: 0;
  font-size: 0;
  text-align: center;
  // display: flex;
  // justify-content: space-around;
  li {
    padding: 0;
    margin: 0 $spacing * 0.5;
    display: inline-block;
    &:before {
      display: none;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  a {
    text-decoration: none;
    display: inline-block;
    font-size: rem-calc(16);
    font-weight: normal;
    text-transform: uppercase;
    color: #fff;
    background-color: #404040;

    padding: $spacing * 0.5 $spacing;
    border-radius: 4px 4px 0 0;
    @media #{$large-up} {
      padding-left: $spacing * 2;
      padding-right: $spacing * 2;
    }
    &:hover,
    &[aria-selected] {
      outline: 0;
      color: $body-font-color;
      background-color: #fff;
    }
    &:focus {
      outline: 1px dotted black;
    }
  }
}

.panel {
  outline: none;
  padding: $spacing 0 $spacing * 2 0;
  > p, > ul, > h2, > h3, > h4, > h5, > h6 {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  h4 + p{
    margin-top: .5rem;
  }

}
#fechas-y-precios{
  h3{
    border-top: 1px solid #979797;
    padding-top: 1.5rem;
    margin-top: 1.5rem;
    text-transform: none;
  }
  div>h3:first-child{
    border:none;
    margin-top: 0;
    padding-top: 0;
  }
}