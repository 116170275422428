.results {
  @include undolist;
  &__item {
    border-bottom: 1px solid $gray2;
    margin-top: $spacing;
    padding-bottom: $spacing;
    @include single-transition(all,2s);
    -webkit-tap-highlight-color: transparent;
    &:hover{
      h2 a{
        color: $primary-color;
        text-decoration: none;
      }
    }
    &.removed{
      transform: translateX(-300%);
      opacity: 0;
    }
  }
  &__head {
    //@media #{$medium-up} {
    @media only screen and (min-width: 47em){
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
  }
  &__wrapper {
    display: flex;
    align-items: flex-end;
  }
  &__title {
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 2.63px;
    font-size: rem-calc(14);
    //@media #{$medium-up} {
    @media only screen and (min-width: 48em){
      font-size: rem-calc(18);
    }
    a {
      color: #000;
      text-decoration: none;
      //@include hover;
    }
  }
  &__img {
    margin-right: $spacing * 0.5;
    min-width: 150px;
    //@media #{$medium-up} {
    @media only screen and (min-width: 48em){
      margin-right: $spacing;
    }
    @media #{$large-up} {
      margin-right: $spacing * 1.5;
    }
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      //@media #{$medium-up} {
      @media only screen and (min-width: 48em){
        width: auto;
        height: auto;
        object-fit: none;
      }
    }
  }
  &__container {
    display: flex;
  }
  &__container-2 {
    display: flex;
    flex-direction: column;
    margin-top: 0 !important;
    //@media #{$medium-up} {
    @media only screen and (min-width: 48em){
      flex: 1;
    }
    .media__img {
      display: none;
      max-width: 190px;
      //@media #{$medium-up} {
      @media only screen and (min-width: 48em){
        display: block;
      }
    }
    .media__content {
      margin-top: 0;
    }
  }
  &__data {
    @media #{$small-only} {
      margin-top: 0 !important;
    }
    //@media #{$medium-up} {
    @media only screen and (min-width: 48em){
      margin-top: $spacing;
    }
    dl {
      line-height: 1.5;
      @include cf;
      //@media #{$medium-up} {
      @media only screen and (min-width: 48em){
        display: flex;
        align-items: baseline;
      }
    }
    dt,
    dd {
      margin-top: 0;
      float: left;
    }
    dt {
      clear: left;
      margin-right: $spacing * 0.125;
      font-size: rem-calc(14);
      //@media #{$medium-up} {
      @media only screen and (min-width: 48em){
        clear: none;
        text-transform: uppercase;
        font-size: rem-calc(10);
        font-weight: bold;
      }
      &:first-of-type {
        display: none;
      }
    }
    dd {
      font-size: rem-calc(16);
      line-height: 1.25;
      white-space: nowrap;
      //@media #{$medium-up} {
      @media only screen and (min-width: 48em){
        font-size: rem-calc(22);
        font-weight: 300;
        margin-right: $spacing * 0.5;
      }
    }
  }
  &__dif {
    span {
      //@media #{$medium-up} {
      @media only screen and (min-width: 48em){
        @include visually-hidden;
      }
    }
    svg {
      display: none !important;
      //@media #{$medium-up} {
      @media only screen and (min-width: 48em){
        display: block !important;
      }
    }
  }
  &__months {
    list-style: none;
    padding-left: 0;
    text-transform: uppercase;
    font-weight: 900;
    color: $primary-color;
    letter-spacing: 2px;
    display: flex;
      flex-wrap: wrap;
    //@media #{$medium-up} {
    @media only screen and (min-width: 48em){

      align-items: center;
      justify-content: center;
    }
    li {
      font-size: rem-calc(12);
      margin-top: 0;
       display: inline;
       margin-right: $spacing * 0.5;
      //@media #{$medium-up} {
      @media only screen and (min-width: 48em){

        border-right: 1px solid $gray2;
        padding-right: $spacing * 0.125;
        margin-right: $spacing * 0.125;
        &:last-child {
          border-right: 0;
          padding-right: 0;
          margin-right: 0;
        }
      }
      &.active abbr {
        @media #{$medium-up} {
          // border-bottom: 2px solid;
        }
      }
    }
    li:not(.active) {
      //display: none;
      //@media #{$medium-up} {
      //@media only screen and (min-width: 48em){
        color: $gray2;
        display: inline;
        font-weight: 300;
      //}
    }
    abbr {
      border-bottom: 0;
      text-decoration: none;
    }
  }
  &__descr {
    font-size: rem-calc(14);
  }
  &__price {
    text-transform: uppercase;
    color: $primary-color;
    font-weight: 900;
    font-size: rem-calc(18);
    letter-spacing: 3.38px;
    //@media #{$medium-up} {
    @media only screen and (min-width: 48em){
      margin-top: 0;
    }
  }
}

// AppendAround
/*.js {
  .price-mobile,
  .descr-mobile {
    @media #{$medium-up} {
      display: none;
    }
  }
  .price-desktop,
  .descr-desktop {
    display: none;
    @media #{$medium-up} {
      display: block;
      margin-top: 0;
    }
  }
}*/

// fav
.fav {
  background-color: transparent;
  margin: 0 0 0 $spacing * 0.5 !important;
  padding: 0;
  line-height: 0;
  cursor: pointer;
  transition: transform 1s ease;
  backface-visibility: hidden;
  will-change: transform;
  border-radius: 50%;
  background-position: center;
  transition: background 0.8s;
  &:focus{
    outline: 0;
    //box-shadow: 0 2px 14px rgba(0,0,0,.11), 0 1px 3px rgba(0,0,0,.08);
    box-shadow: 0 -6px 12px rgba(0,0,0,.11), 0 6px 12px rgba(0,0,0,.08);
  }

  &:hover{
     //transform:scale(1.1);
     transform: scale(1.07);
     transform: scale3d(1.07, 1.07, 1.07);
  }

  &:before {
    content: "";
    @include sprite("ico-miseleccion-boto");
    font-size: 1rem;
  }
  &--on {
    &:before {
      @include sprite("ico-miseleccion-boto-on");
    }
    &:focus{

        box-shadow: 0 -6px 12px rgba($primary-color,.11), 0 6px 12px rgba($primary-color,.08);
      }
  }
}
@media #{$small-only} {
  .isDesktop {
    .results__container{
      display: block;
      .media__content{
        padding: 1em 0;
      }
      .results__img img{
        min-width: 320px;
      }
    }
  }
}

#list{
    position: relative;
    margin: 0;
}
.loading{
    height: 100%;
    width:100%;
    min-height: 100vh;
    text-align: center;
    position: absolute;
    z-index: 2;
    top:0;
}
.loading .load{
    background-color: #fff;
    opacity: .9;
     min-height: 100vh;
     height: 100%;
}
.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: $primary-color;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% { -webkit-transform: scale(0.0) }
  50% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  } 50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}
