// @include typi-init;
html {
  font-size: 100%;
  line-height: 1.5;
}
body {
  background: $body-bg;
  color: $body-font-color;
  font-family: $body-font-family;
}
h1,
.t1 {
  font-size: rem-calc(30);
  font-weight: 900;
  @media #{$medium-up} {
    font-size: rem-calc(34);
  }
  @media #{$large-up} {
    font-size: rem-calc(38);
  }
  .ruta & {
    text-transform: uppercase;
    font-size: rem-calc(24);
    letter-spacing: 3px;
    margin: $spacing;
    a{
      color: $body-font-color;
      text-decoration: none;
    }
    @media #{$medium-up} {
      font-size: rem-calc(26);
    }
    @media #{$large-up} {
      font-size: rem-calc(28);
      letter-spacing: 5.25px;
    }
  }
}
h2,
.t2 {
  font-size: rem-calc(20);
  font-weight: 900;
  line-height: 1.2;
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
  @media #{$medium-up} {
    font-size: rem-calc(24);
  }
  .ruta-info & {
    font-family: Piedra, sans-serif;
    font-weight: normal !important;
    text-align: center;
    font-size: rem-calc(34) !important;
    text-transform: none !important;
    color: $primary-color;
    letter-spacing: 0 !important;
    @media #{$medium-up} {
      font-size: rem-calc(34);
    }
    @media #{$large-up} {
      font-size: rem-calc(36);
      width: 70%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
h3,
.t3 {
  font-size: rem-calc(20);
  font-weight: bold;
  line-height: 1.2;
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
}
h4,
.t4 {
  color: #000 !important;
  font-size: rem-calc(16);
  font-weight: bold;
  line-height: 1.2;
}
p,
ol,
ul,
dl {
  // font-size: rem-calc(16);
  // line-height: 1.6;
  // @media #{$medium-up} {
  //   font-size: rem-calc(18);
  // }

  // classes per element contenidor
  .smallest & {
    font-size: rem-calc(12);
  }
  .smaller & {
    font-size: rem-calc(14);
  }
  .small & {
    font-size: rem-calc(16);
  }
  .normal & {
    font-size: rem-calc(18);
  }
  .big & {
    font-size: rem-calc(20);
  }
  .bigger & {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  .biggest & {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }

  // classes directes a l'element de text
  &.smallest {
    font-size: rem-calc(12);
  }
  &.smaller {
    font-size: rem-calc(14);
  }
  &.small {
    font-size: rem-calc(16);
  }
  &.normal {
    font-size: rem-calc(18);
  }
  &.big {
    font-size: rem-calc(20);
  }
  &.bigger {
    font-size: rem-calc(21);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(22);
    }
  }
  &.biggest {
    font-size: rem-calc(22);
    line-height: 1.4;
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
  }
}

ol,
dl,
dt,
dd,
figure,
img,
form,
hr,
table {
  margin: 0;
  padding: 0;
  // font-size: rem-calc(15);
}
.main-content {
  ul,
  ol,
  figure {
    @include stack-small;
  }
}
ul {
  list-style: none;
  font-weight: normal;
  main & {
    li {
      position: relative;
      padding-left: $spacing * 0.75;
      margin-left: $spacing;
      // margin-bottom: $spacing * 0.25;
      &:before {
        content: "";
        display: block;
        height: $spacing * 0.25;
        width: $spacing * 0.25;
        position: absolute;
        left: 0;
        top: 0.65em;
        background-color: $primary-color;
      }
      ul {
        color: #3f3f3f;
      }
      li {
        font-size: rem-calc(14);
        padding-left: $spacing * 0.75;
        &:before {
          height: $spacing * 0.25;
          width: $spacing * 0.25;
          top: 0.6em;
        }
      }
    }
  }
}

// llista simple, sense klanders
ul,
ol {
  &.simple-list {
    list-style: none;
    li {
      padding-left: 0;
      &:before {
        display: none;
      }
    }
  }
}

ol {
  main & {
    padding-left: $spacing;
    li {
      margin-left: $spacing;
    }
  }
}

.dl-inline {
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
  dt {
    float: left;
    margin-right: 0.3em;
  }
}

// figure {
//   img {
//     margin-bottom: 0;
//   }
// }
figcaption {
  font-size: rem-calc(12);
  // margin-top: $spacing * 0.5;
  .u-wider & {
    // margin: 0 auto;
    // max-width: 82rem;
    // width: 83.33333%;
    padding-left: $column-gutter * 0.5;
    padding-right: $column-gutter * 0.5;
  }
}

::selection {
  color: #fff;
  background-color: $primary-color;
}
strong,
b {
  font-weight: 600;
}
// em,
// i {
// }

.upp {
  text-transform: uppercase;
}

// links underline
a {
  color: $primary-color;
  &:hover {
    text-decoration: none;
  }
}
/*a,
button,
input,
textarea {
  &:focus {
    outline: 3px solid $focus-color;
    .breadcrumb & {
      outline-color: #fff;
    }
  }
}*/

p.dest,
.dest p {
  font-weight: 300;
  font-size: rem-calc(20);
  line-height: 1.4;
  padding-left: $spacing;
  border-left: 1px solid $primary-color;
  @media #{$medium-up} {
    font-size: rem-calc(24);
  }
  @media #{$large-up} {
    font-size: rem-calc(28);
    width: 50%;
  }
  strong {
    font-weight: bold;
  }
}
p.dest2,
.dest2 p {
  font-family: Piedra, sans-serif;
  font-weight: normal;
  font-size: rem-calc(20);
  line-height: 1.4;
  padding-left: $spacing;
  border-left: 4px solid $primary-color;
  @media #{$medium-up} {
    font-size: rem-calc(24);
  }
  @media #{$large-up} {
    font-size: rem-calc(28);
    width: 50%;
  }
}
p.dest3,
.dest3 p,
p.dest4,
.dest4 p {
  text-align: center;
  color: #fff;
  background-color: $tertiary-color;
  font-weight: 900;
  font-size: rem-calc(18);
  line-height: 1.4;
  padding: $spacing;
  @media #{$medium-up} {
    font-size: rem-calc(20);
  }
  @media #{$large-up} {
    font-size: rem-calc(22);
  }
}
p.dest4,
.dest4 p {
  background-color: $secondary-color;
}

hr {
  border: 0;
  border-top: 1px solid #cccccc;
}
table {
  width: 100%;
  font-size: rem-calc(16);
  border-collapse: initial;
  th,
  td {
    vertical-align: top;
  }
  thead {
    th {
      color: #fff;
      background-color: $primary-color;
      font-weight: bold;
      text-align: left;
      padding: $spacing * 0.25 $spacing;
      white-space: nowrap;

    }
    th:first-child {
      border-radius: 4px 0 0 4px;
    }
    th:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
  tbody {
    td,
    th {
      border-bottom: 1px solid #d7d7d7;
      padding: $spacing * 0.5 $spacing;
      text-align: left;
    }
    /*tr:last-child {
      td,
      th {
        border-bottom: 0;
      }
    }*/
    td{
      &.data{
          white-space: nowrap;
      }
    }
    @media #{$medium-up} {
      th{
        &.title{
          vertical-align: middle;
          //padding-top: 0;
          text-align: center;
          font-size: rem-calc(26);
        }
      }
    }
  }
  .preu {
    font-weight: bold;
    text-align: right;
  }
}
.table-container {
  width: 100%;
  overflow-y: auto;
  _overflow: auto;
  margin: 0 0 1em;
}
.table-container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 14px;
  height: 14px;
}

.table-container::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 3px solid #fff;
  background-color: rgba(0, 0, 0, 0.3);
}
.inline-list {
  @include undolist;
  font-size: 0;
  margin-left: -$spacing;
  margin-right: -$spacing;
  text-align: center;
  li {
    font-size: 1rem;
    display: inline-block;
    vertical-align: middle;
    margin: $spacing $spacing 0;
  }
  &--logos {
    // li {
    //   margin: 0 $spacing $spacing * 2;
    //   &:last-child {
    //     margin-bottom: $spacing * 2;
    //   }
    // }
  }
  // img {
  //   margin-bottom: 0;
  // }
}
.intro {
  font-weight: bold;
  font-size: rem-calc(18);
  line-height: 1.4;
  text-align: center;
  @media #{$medium-up} {
    font-size: rem-calc(20);
  }
}
.invoice-box{
  table tbody td, table tbody th {
    border-bottom: none;
  }
}

.dest-2{
  border: 1px solid #efefef;
  padding: $spacing;
  border-width: 1px 0;
  text-transform: uppercase;
  color: $secondary-color;
}
.dest-3{
  background: #efefef;
  padding: $spacing;

  font-weight: bold;
}
.llista_separadors{
  li{
    border-bottom: 1px solid #efefef;
    margin-left: 0;
    padding-bottom: $spacing;
    margin-top: $spacing;
  }
}
.llista_fons{
  background-color:#efefef;
  padding: $spacing;

}