.breadcrumb {
  background-color: $quaternary-color;
  // margin-bottom: $spacing;
  padding: $spacing * 0.25 0;
  //   @media #{$large-up} {
  //     margin-bottom: $spacing * 4;
  //   }
  ol {
    list-style: none;
    font-size: rem-calc(14);
    //text-transform: uppercase;
    padding-left: 0;
  }
  li {
    display: inline;
    margin-left: 0;
    &:after {
      content: "";
      display: inline-block !important;
      @include triangle($spacing * 0.125, #fff, left);
      margin-left: $spacing * 0.25;
      margin-right: $spacing * 0.125;
    }
    &:last-child {
      font-weight: bold;
      &:after {
        display: none !important;
      }
    }
  }
  a {
    color: #000;
    display: inline-block;
    vertical-align: middle;
    @include hover;
  }
}
