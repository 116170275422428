.fotos-container {
  @media #{$medium-up} {
    @include grid-row();
  }
}
.h-fotos {
  @media #{$medium-up} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    /*grid-gap: $spacing * 0.5;*/

    display:-ms-grid;
    -ms-grid-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
  }
  div {
    text-align: center;
    text-transform: uppercase;
    background-color: $gray2;
    position: relative;
    background-size: cover;
    height: rem-calc(370);
    display: flex;
    justify-content: center;
    align-items: center;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      transition: background-color 0.15s ease-in-out;
    }
    &:hover {
      &:before {
        background-color: rgba(#000, 0.25);
      }
    }
  }
  h2 {
    font-weight: 900;
    font-size: rem-calc(60);
    text-shadow: 0 8px 14px rgba(0, 0, 0, 0.5);
    z-index: 1;
    @media #{$medium-up} {
      font-size: rem-calc(70);
    }
    @media #{$large-up} {
      font-size: rem-calc(80);
    }
  }
  a {
    color: #fff;
    text-decoration: none;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: transparent;
      transition: background-color 0.15s ease-in-out;
    }
  }
}
.foto-1 {
	@media #{$medium-up} {
	-ms-grid-row: 1;
	-ms-grid-column: 1;
	margin-right: $spacing * 0.5;
	margin-bottom: $spacing * 0.5;
    }
  background-image: url("img/road.jpg");
}
.foto-2 {
  background-image: url("img/btt.jpg");
  @media #{$medium-up} {
    grid-row-end: span 2;
    -ms-grid-row: 1;
    -ms-grid-column: 2;
    -ms-grid-row-span: 2;
    height: auto !important;
    margin-bottom: $spacing * 0.5;
    @supports (display: grid) {
      height: auto !important;
    }
  }
}
.foto-3 {
	@media #{$medium-up} {
	-ms-grid-row: 2;
	-ms-grid-column: 1;
	margin-right: $spacing * 0.5;
    }

  background-image: url("img/slow.jpg");
}
