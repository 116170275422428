.h-icons {
  @include undolist;
  @include block-grid(2);
  text-transform: uppercase;
  font-size: rem-calc(14);
  text-align: center;
  @media screen and (min-width: 40em) {
    @include block-grid(3);
  }
  @media #{$medium-up} {
    @include block-grid(4);
    font-size: rem-calc(16);
  }
  @media #{$large-up} {
    @include block-grid(6);
  }
  li {
    margin-top: 0;
    line-height: 1.3;
    padding-top: 80px;
    @media screen and (min-width: 40em) {
      padding-top: 100px;
    }
    @media #{$large-up} {
      padding-top: 120px;
    }
    &:before {
      content: "";
      display: block !important;
      font-size: 0.625rem;
      background-color: transparent;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      @media screen and (min-width: 40em) {
        font-size: 0.8rem;
      }
      @media #{$large-up} {
        font-size: 1rem;
      }
    }
    &:after {
      @media #{$large-up} {
        content: "";
        display: block;
        width: 1px;
        height: 13rem;
        background-color: #c9c9c9;
        position: absolute;
        right: 0;
        top: 0;
        // transform: translateY(-50%);
      }
    }
    &:last-child:after {
      display: none;
    }
    &:nth-child(1) {
      &:before {
        @include sprite("pict-1000-clientes");
      }
      strong {
        font-size: rem-calc(30);
      }
    }
    &:nth-child(2) {
      &:before {
        @include sprite("pict-pioneros");
        top: 10px;
      }
    }
    &:nth-child(3) {
      &:before {
        @include sprite("pict-medio-ambiente");
      }
    }
    &:nth-child(4) {
      &:before {
        @include sprite("pict-personal");
        top: 24px;
        @media screen and (min-width: 40em) {
          top: 15px;
        }
      }
    }
    &:nth-child(5) {
      &:before {
        @include sprite("pict-calidad");
      }
    }
    &:nth-child(6) {
      &:before {
        @include sprite("pict-economia-local");
        top: 6px;
      }
    }
  }
  strong {
    font-weight: 900;
    display: block;
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
  }
}
