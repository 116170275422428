.collapsible {
  &__title {
    font-size: rem-calc(16);
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
    button {
      all: inherit;
      border: 0;
      padding: $spacing * 0.75;
      margin: 0;
      width: 100%;
      color: #fff;
      background-color: $primary-color;
      border-radius: 4px;
      position: relative;
      @media #{$medium-up} {
        display: flex;
        padding: $spacing $spacing * 1.5;
      }
      > * + * {
        margin-top: $spacing * 0.25;
      }
      &:focus {
        outline: 3px solid $focus-color;
      }
      &:after {
        content: "";
        @include sprite("ico-desplega");
        position: absolute;
        top: $spacing * 0.75;
        right: $spacing * 0.75;
        @media #{$medium-up} {
          top: $spacing;
          right: $spacing * 1.5;
        }
      }
      &[aria-expanded="true"] {
        &:after {
          @include sprite("ico-plega");
        }
        &:before {
          @include triangle($spacing * 0.5, $primary-color, top);
          position: absolute;
          bottom: -24px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      span {
        display: block;
        line-height: 1.2;
      }
    }
    + div {
      padding: 0 $spacing * 0.5;
      @media #{$medium-up} {
        padding: $spacing $spacing * 2;
      }
    }
  }
  .etapas {
    text-transform: uppercase;
    font-size: rem-calc(28);
    font-weight: normal;
    white-space: nowrap;
    @media #{$medium-up} {
      font-size: rem-calc(30);
    }
    @media #{$large-up} {
      font-size: rem-calc(32);
    }
  }
  .nivel {
    text-transform: uppercase;
    font-size: rem-calc(14);
    white-space: nowrap;
    font-weight: normal;
  }
  .descr {
    font-size: rem-calc(16);
    font-weight: normal;
    @media #{$medium-up} {
      font-size: rem-calc(18);
      padding: 0 $spacing * 5;
      margin-top: 0;
    }
    @media #{$large-up} {
      font-size: rem-calc(20);
    }
  }
}
