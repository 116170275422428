.head {
  text-align: center;
  margin: $spacing * 2 0;
  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 60%;
    margin: $spacing * 2 auto 0;
    background-color: $quaternary-color;
  }
}
