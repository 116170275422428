.h-boxes {
  @media screen and (min-width: 58em) {
    @include grid-row();
  }
  &__wrapper {
    @media screen and (min-width: 58em) {
      display: flex;
      flex-wrap: wrap;
      @include grid-column(12);
    }
    > * {
      @media screen and (min-width: 58em) {
        margin-top: 0;
        margin-left: 0.375rem;
        margin-right: 0.375rem;
        width: calc(33.333% - 0.75rem);
      }
    }
  }
  .foto {
    display: none;
    background-color: $gray2;
    background-size: cover;
    background-position: 0 100%;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    color: #fff;
    letter-spacing: 3.75px;
    padding: $spacing * 2;
    text-decoration: none;
    font-size: rem-calc(20);
    text-align: center;
    position: relative;
    @media screen and (min-width: 58em) {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(to top, #000000, transparent);
      height: 50%;
    }
    span {
      z-index: 1;
    }
  }
  .club {
    background-image: url("img/foto-club-home.jpg");
  }
  .tienda {
    background-image: url("img/foto-tienda-home.jpg");
  }
}
