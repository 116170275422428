.section {
  text-align: center;
  padding: $spacing 0;
  @media #{$medium-up} {
    padding: $spacing * 1 0;
  }
  @media #{$large-up} {
    padding: $spacing * 3 0;
  }
  &--1 {
    background-color: $gray1;
    background-image: linear-gradient(to bottom, #ededed, #ffffff);
  }
  &--2 {
    background-color: #fff;
     padding: $spacing * 1 0;
  }
  &--3 {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: rem-calc(100);
      background-image: linear-gradient(to bottom, #ffffff, #eee);
    }
  }
  &--4 {
    color: #fff;
    background-color: $secondary-color;
  }
  h2 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 3px;
    .home & {
      font-size: rem-calc(20);
      @media #{$medium-up} {
        letter-spacing: 5.63px;
        font-size: rem-calc(24);
      }
      @media #{$large-up} {
        font-size: rem-calc(30);
      }
    }
  }
}
