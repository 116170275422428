.info-btns {
  text-align: center;
  padding-top: 0;
  @media #{$medium-up} {
    display: none;
  }
  .btn {
    width: 100%;
    font-size: rem-calc(14);
    @media screen and (min-width: 30.0625em) {
      width: auto;
      margin-left: $spacing * 0.5;
      margin-right: $spacing * 0.5;
    }
    &.active{
      opacity: .35;
    }
  }
  .content{
    text-align: left;
    h2{
      margin-bottom: $spacing;
      padding-bottom:  $spacing;
      border-bottom: 1px solid #000;
    }
    .collapsible{
      margin-bottom: $spacing;
    }
  }
}
