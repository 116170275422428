.ruta-info {
  display: none;
  @media #{$medium-up} {
    display: block;
  }
  figure img {
    border-radius: 50%;
  }
  figcaption {
    font-style: italic;
  }
  &--mobile {
    @media #{$medium-up} {
      display: none;
    }
    .results__img {
      margin-right: $spacing;
      min-width: 120px;
      img {
        width: auto;
        height: auto;
      }
    }
    .results__months {
      border: solid $gray2;
      border-width: 1px 0;
      padding: $spacing * 0.5 0;
    }
  }
  .results__months {
    border: solid $gray2;
    border-width: 1px 0;
    padding: $spacing 0;
    li {
      margin-left: 0;
      font-size: 1rem;
      padding-right: $spacing * 0.25;
      margin-right: $spacing * 0.25;
      @media #{$large-up} {
        padding-right: $spacing * 0.5;
        margin-right: $spacing * 0.5;
      }
    }
  }
}
