.share-btn {
  display: none;
  @media #{$medium-up} {
    display: block;
    background-color: $primary-color;
    position: fixed;
    left: 0;
    top: 21.5rem;
    z-index: 3;
    margin: 0;
    padding: 0;
    width: $spacing * 1.5;
    height: $spacing * 1.5;
    border-radius: 0 4px 4px 0;
    &:before {
      content: "";
      display: block;
      // @include triangle(5px, #fff, left);
      @include sprite("ic_reply_24px");
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      // margin-left: -2px;
    }
    &[aria-expanded="true"] {
      left: 71px;
      &:before {
        // @include triangle(5px, #fff, right);
        // margin-left: -2px;
        transform: translateX(-50%) translateY(-50%) scaleX(-1);
      }
    }
  }
  @media #{$large-up} {
    top: 21.7rem;
  }
}
.share-list {
  display: none;
  text-align: center;
  @include undolist;
  position: fixed;
  left: 0;
  top: 21.5rem;
  margin-top: 0;
  z-index: 3;
  background-color: #000;
  background-color: rgba(#000, 0.5);
  padding: $spacing * 0.75;
  > * + * {
    margin-top: $spacing !important;
  }
  @media #{$medium-up} {
    display: block;
  }
  @media #{$large-up} {
    top: 21.7rem;
  }
  a {
    display: block;
    transform: scale(0.75);
  }
  svg {
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
  }
  a:hover svg {
    opacity: 0.8;
  }
  &--mobile {
    display: block;
    position: absolute;
    top: rem-calc(60);
    right: 0;
    left: auto;
    width: rem-calc(82);
    @media screen and (min-width: 30.0625em) {
      right: $spacing * 0.5;
      top: rem-calc(72);
    }
    @media screen and (min-width: 40.0625em) {
      top: rem-calc(64);
      left: $spacing * 0.5;
      width: auto;
      padding: $spacing * 0.5;
      li {
        display: inline-block;
        margin: $spacing * 0.25 !important;
        vertical-align: middle;
      }
    }
  }
}
.share-container {
  position: relative;
}
