.pagination {
  text-align: center;
  font-size: 0;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
  li {
    padding: 0;
    display: inline-block;
    font-size: rem-calc(12);
    margin-top: 0;
    margin-left: 0;
    vertical-align: middle;
    &:before {
      display: none;
    }
  }
  a {
    text-decoration: none;
    display: block;
    color: #9e9e9e;
    background-color: #eaeaea;
    width: $spacing * 1.75;
    height: $spacing * 1.75;
    text-align: center;
    margin: 0 $spacing * 0.25;
    line-height: 3.3;
  }
  .current a,
  a:hover,
  a:focus {
    color: #fff;
    background-color: $tertiary-color;
  }
  .unavailable {
    a {
      width: auto;
      background-color: transparent;
      &:hover,
      &:focus {
        color: inherit;
        text-decoration: underline;
        background-color: transparent;
      }
    }
  }
  .prev,
  .next {
    text-align: center;
    a {
      background-color: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      &:before {
        content: "";
        // display: inline-block;
      }
    }
  }
  .prev {
    a {
      &:before {
        @include triangle(0.35rem, $primary-color, right);
      }
    }
  }
  .next {
    a {
      &:before {
        @include triangle(0.35rem, $primary-color, left);
      }
    }
  }
}
