@media screen and (min-width: 40em) {
  .media {
    display: flex;
    align-items: flex-start;
    &--reverse {
      flex-direction: row-reverse;
      .media__content {
        padding-left: 0;
        padding-right: $spacing;
      }
    }
  }
  .media__content {
    flex: 1;
    padding-left: $spacing;
    margin-top: 0;
  }
  .media__img figure {
    max-width: $spacing * 15;
  }
}
@media #{$medium-up} {
  .media {
    margin-left: -25%;
    .results & {
      margin-left: 0;
    }
    &--reverse {
      margin-left: 0;
      margin-right: -25%;
    }
  }
  .media__img figure {
    max-width: $spacing * 18;
  }
}
