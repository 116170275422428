.scrolltop {
  transition: opacity 0.2s;
  z-index: 2;
  right: $spacing;
  bottom: $spacing;
  width: 44px;
  height: 44px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0.5;
  border-radius: 2px;
  display: block;
  display: none;
  position: fixed;
  &:hover,
  &:focus {
    // background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
  img {
    margin-bottom: 0;
  }
}
