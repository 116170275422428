@media #{$medium-up} {
  .js .main-header--sticky {
    position: fixed;
    top: -90px;
    left: auto;
    right: auto;
    margin: 0 auto;
    padding: 0 $column-gutter * 0.5;
    width: 100%;
    z-index: 2;
  }
  // .sticky-nav {
  //   .site-menu {
  //     background-color: $tertiary-color;
  //   }
  // }
}

// logo sticky
.site-menu > li:first-child {
  display: none;
  @media #{$medium-up} {
    .sticky-header & {
      display: block;
      float: left;
      margin-top: 5px;
      margin-right: -77px; /*amplada del logo*/
      a {
        border-bottom: 0;
        margin: 0;
      }
      img {
        height: rem-calc(35);
      }
    }
  }
}
