.news {
  @include undolist;
  &--cols {
    columns: 1;
    column-gap: $spacing;
    @media screen and (min-width: 35em) {
      columns: 2;
    }
    @media #{$medium-up} {
      columns: 3;
    }
    // @media #{$xlarge-up} {
    //   columns: 4;
    // }
  }
  &__item {
    break-inside: avoid;
    border-bottom: 1px solid $gray1;
    .news--cols & {
      border-bottom: 0;
    }
    &--00 {
      .news__date {
        order: -1;
      }
    }
    &--01 {
      .news__panel {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.22);
      }
      .news__date {
        order: -2;
        margin-top: $spacing * 0.5;
      }
      .news__pict {
        order: -3;
      }
      .news__cat {
        top: $spacing;
        left: $spacing;
      }
    }
    &--02 {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      .news__panel {
        padding: $spacing * 0.5 0 $spacing 0;
      }
      .news__date {
        order: -2;
        margin-top: 0;
      }
      .news__pict {
        flex: 0 0 auto;
      }
      .news__cat {
        top: 0;
        left: $spacing * 0.5;
      }
    }
    &--03 {
      background-size: cover;
      .news__panel {
        padding: 0;
        box-shadow: none;
        background-color: #222e39;
        background-color: rgba(#222e39, 0.7);
        padding: $spacing * 3.5 $spacing $spacing;
        color: #fff;
        transition: width 0.15s ease-in-out;
        position: relative;
        @media screen and (min-width: 35em) {
          width: 50%;
        }
        @media #{$medium-up} {
          width: 33.333%;
          padding: $spacing * 3.5 $spacing * 1.5 $spacing * 1.5;
        }
        @media #{$large-up} {
          width: 40%;
          padding: $spacing * 5 $spacing * 2;
        }
      }
      .news__title {
        font-family: Piedra, sans-serif;
        font-weight: normal;
        font-size: rem-calc(30);
        @media #{$medium-up} {
          font-size: rem-calc(36);
        }
        a {
          color: #fff;
          @include hover;
        }
      }
      .news__date {
        order: -1;
        @media #{$medium-up} {
          font-size: rem-calc(18);
        }
      }
      .news__cat {
        top: $spacing;
        left: -14px;
        @media #{$large-up} {
          top: $spacing * 2;
        }
        &:before {
          content: "";
          @include sprite("corner-categ");
          font-size: 1rem;
          position: absolute;
          left: 0;
          bottom: -14px;
        }
        &:after {
          content: "";
          position: absolute;
          display: block;
          left: 14px;
          right: 6px;
          bottom: -6px;
          height: 6px;
          background-color: rgba(#000000, 0.12);
        }
      }
      .news__text {
        margin-top: $spacing * 0.5;
        @media #{$medium-up} {
          font-size: rem-calc(18);
        }
      }
    }
  }
  &__panel {
    padding: $spacing;
    display: flex;
    flex-direction: column;
  }
  &__title {
    margin-top: $spacing * 0.5;
    font-size: rem-calc(22);
    font-weight: 900;
    a {
      color: #000;
      @include hover;
    }
  }
  &__date {
    font-size: rem-calc(14);
  }
  &__cat {
    font-family: Piedra, sans-serif;
    font-weight: normal;
    font-size: rem-calc(18);
    background-color: $quaternary-color;
    padding: $spacing * 0.25 $spacing * 0.5;
    position: absolute;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    a {
      color: $tertiary-color;
      @include hover;
    }
  }
}

.main-content {
  .news > * + * {
    margin-top: $spacing;
  }
  .news__pict {
    margin-top: 0;
    margin-right: auto;
    margin-bottom: auto;
  }
  .news__cat {
    margin-top: 0;
  }
}

.news__item--00 {
  .news__date {
    margin-top: 0;
  }
}

.ie9 .news__cat {
  position: static;
  display: inline-block;
}
