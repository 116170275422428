.etapas-list {
  @include undolist;
  > li {
    border-bottom: 1px solid $gray2;
    padding-bottom: $spacing * 0.5;
    &:last-child {
      border-bottom: 0;
    }
    @media #{$medium-up} {
      display: flex;
    }
  }
  h4 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: rem-calc(18);
    //white-space: nowrap;
    @media #{$medium-up} {
      font-size: rem-calc(20);
      margin-right: $spacing;
      min-width: 25%;
      width: 28%;
    }
    span {
      display: block;
      font-weight: bold;
      text-transform: none;
      font-size: rem-calc(18);
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
    }
  }
  .wrap {
    @media #{$medium-up} {
      margin-top: 0;
      width: 70%;
    }
  }
  &__data {
    @include undolist;
    font-weight: 300;
    li {
      font-size: rem-calc(22);
      color: #000;
      margin-top: 0;
      @media screen and (min-width: 30em) {
        display: inline-block;
        margin-right: $spacing * 0.5;
      }
      @media #{$medium-up} {
        font-size: rem-calc(24);
      }
      @media #{$large-up} {
        font-size: rem-calc(26);
      }
    }
    .dist,
    .desniv {
      span {
        font-size: rem-calc(14);
        font-weight: 900;
        @media #{$medium-up} {
          font-size: rem-calc(16);
        }
      }
    }
    .dist {
      @media screen and (min-width: 30em) {
        border-right: 1px solid $gray2;
        padding-right: $spacing * 0.5;
      }
    }
    .desniv span:first-child {
      text-transform: uppercase;
    }
  }
  svg {
    margin-top: 0;
  }
}
