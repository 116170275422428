@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x: 0 !default;
$hamburger-padding-y: 0 !default;
$hamburger-layer-width: 24px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-spacing: 4px !default;
$hamburger-layer-color: #fff !default;
$hamburger-layer-border-radius: 0 !default;
$hamburger-hover-opacity: 1 !default;
$hamburger-hover-transition-duration: 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;
