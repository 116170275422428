.main-footer {
  padding: $spacing * 1.5 0;
  text-align: center;
  color: #fff;
  background-color: $tertiary-color;
  // margin-top: $spacing * 3;
  @media #{$medium-up} {
    padding: $spacing * 2 0;
  }
  address {
    font-style: normal;
    br {
      @media #{$medium-up} {
        display: none;
      }
    }
    @media #{$medium-up} {
      span:after {
        content: "·";
        font-weight: bold;
        display: inline;
        margin: 0 $spacing * 0.5;
      }
      span:last-child:after {
        display: none;
      }
    }
  }
  .social {
    transform: scale(0.75);
  }
  &__nav {
    display: flex;
    justify-content: center;
    h2 {
      font-family: Piedra, sans-serif;
      text-transform: uppercase;
      font-size: rem-calc(24);
      font-weight: normal;
      a {
        display: inline-block;
        &:before {
          content: "";
          display: block !important;
          margin-bottom: $spacing;
          font-size: 1rem;
        }
      }
    }
    a {
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    > div {
      border-right: 1px solid #fff;
      border-right-color: rgba(#fff, 0.4);
      padding: 0 $spacing * 2;
      text-align: left;
      &:nth-child(1) {
        ul {
          column-count: 2;
        }
        h2 a {
          &:before {
            @include sprite("ico-peu-btt");
          }
        }
      }
      &:nth-child(2) h2 a {
        &:before {
          @include sprite("ico-peu-slow");
        }
      }
      &:nth-child(3) {
        border-right: 0;
        h2 a {
          &:before {
            @include sprite("ico-peu-road");
          }
        }
      }
    }
    + ul {
      font-size: 0;
      li {
        display: inline;
        font-size: 1rem;
        &:after {
          content: "·";
          margin: 0 $spacing * 0.5;
        }
        &:last-child:after {
          display: none;
        }
      }
      a {
        color: #fff;
        @include hover;
      }
    }
  }
}
