.tools {
  @media #{$medium-up} {
    float: right;
  }
  li {
    @media #{$medium-up} {
      display: inline-block;
      vertical-align: top;
    }
  }
  &__item {
    display: block;
    border-bottom: 1px solid #fff;
    padding: $spacing 0;
    text-transform: uppercase;
    color: #fff;
    font-size: rem-calc(12);
    letter-spacing: 2.25px;
    width: 100%;
    background-color: transparent;
    text-decoration: none;
    text-align: left;
    @media #{$medium-up} {
      border-bottom: 0;
      border-left: 1px solid #fff;
      padding: $spacing * 0.75 $spacing * 0.75 $spacing * 0.25;
    }
    &:hover,
    &:focus {
      text-decoration: underline !important;
    }
    &--icon {
      &:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-right: $spacing * 0.5;
        font-size: 1rem;
      }
    }
    &--tel {
      font-weight: bold;
      font-size: 1rem;
      letter-spacing: 3px;
      @media #{$medium-up} {
        border-left: 0;
        padding-top: 0.9rem;
      }
      &:before {
        @include sprite("ico-telefon");
      }
    }
    &--selec {
      &:before {
        @include sprite("ico-miseleccion");
      }
    }
    &--lang {
      &:hover,
      &:focus {
        text-decoration: none !important;
      }
      @media #{$medium-up} {
        position: relative;
      }
      &:before {
        @include sprite("ico-idiomes");
      }
      &:after {
        content: "";
        display: inline-block;
        vertical-align: middle;
        margin-left: $spacing * 0.5;
        font-size: 1rem;
        @include sprite("ico-fletxa-menu-desplega-copy");
      }
      + ul {
        z-index: 999;
        background-color: #fff;
        padding: $spacing * 0.5;
        @media #{$medium-up} {
          font-size: rem-calc(14);
          margin: 0 $spacing * 0.75;
          width: 10rem;
          display: none;
          .no-js & {
            display: block;
          }
          &[aria-hidden] {
            position: absolute;
          }
          &[aria-hidden="false"] {
            display: block;
          }
        }
        li {
          display: block;
        }
        a {
          color: $body-font-color;
          &:hover,
          &:focus {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
