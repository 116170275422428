.btn {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  // margin-bottom: $spacing;
  padding: rem-calc(14) $spacing * 1.5 rem-calc(12);
  text-align: center;
  color: #fff;
  transition: background-color 0.125s ease-in-out, color 0.125s ease-in-out,
    border-color 0.125s ease-in-out;
  font-size: rem-calc(14);
  font-weight: 900;
  border: 1px solid $primary-color;
  background-color: $primary-color;
  font-size: rem-calc(16);
  border-radius: 4px;
  letter-spacing: 3.02px;
  &:hover,
  &:active {
    text-decoration: none;
    background-color: #fff;
    color: $primary-color;
  }
  &--outline {
    color: $body-font-color;
    border-color: #000;
    background-color: #fff;
    &:hover,
    &:active {
      border-color: $primary-color;
    }
  }
  &--icon {
    padding-left: $spacing * 3;
    position: relative;
    @media screen and (max-width: 40em) {
      padding-left: $spacing * 1.5;
    }
    &:before {
      content: "";
      position: absolute;
      left: $spacing;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1rem;
      // @media screen and (max-width: 40em) {
      //   position: static;
      // }
    }
    span {
      @media screen and (max-width: 40em) {
        @include visually-hidden;
      }
    }
  }
  &--miseleccion {
    &:before {
      @include sprite("ico-miseleccion-boto");
    }
    @include single-transition();
    &:focus{
      outline: 0;
      box-shadow: -6px -12px 6px rgba(0,0,0,.11), 6 12px 6px rgba(0,0,0,.08);
    }
    &-on {
      color: $primary-color;
      border-color: $primary-color;
      &:before {
        @include sprite("ico-miseleccion-boto-on");
      }
      &:focus{
        box-shadow: 0 4px 6px rgba($primary-color,.5), 0 1px 3px rgba(0,0,0,.08);
      }
    }
  }
  &--pdf {
    &:before {
      @include sprite("ico-fitxa-boto");
    }
    &:hover,
    &:active {
      &:before {
        @include sprite("ico-fitxa-boto-on");
      }
    }
  }
  &--share {
    &:before {
      @include sprite("ic_reply_24px-b");
    }
    &:hover,
    &:active {
      &:before {
        @include sprite("ic_reply_24px-b-on");
      }
    }
  }
  &--small {
    text-transform: none;
    font-weight: normal;
    letter-spacing: 0;
    font-size: rem-calc(14);
    padding: rem-calc(6) $spacing * 0.5;
  }
  &--large {
    font-weight: 900;
    letter-spacing: 3.75px;
    font-size: rem-calc(20);
    border-radius: 0;
    padding: $spacing $spacing;
    min-height: rem-calc(144);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &--secondary {
    background-color: $secondary-color;
    border-color: $secondary-color;
    &:hover,
    &:active {
      color: $secondary-color;
    }
  }
}
