.ruta-intro {
  display: none;
  @media #{$medium-up} {
    display: block;
    overflow-x: hidden;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &__info {
    background-color: $gray1;
    background-image: linear-gradient(to bottom, #ededed, #ffffff);
    padding: $spacing;
    @media #{$medium-up} {
      position: relative;
      padding: $spacing 0 $spacing $spacing;
      background: none;
      position: relative;
      dl,img{
        z-index: 1;
        position: relative;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 0;
        right: -200%;
        background-color: $gray1;
        background-image: linear-gradient(to bottom, #ededed, #ffffff);
        width: 300%;
        opacity: .9;
      }
    }
    @media #{$large-up} {
      padding: $spacing 0 $spacing $spacing * 2;
    }
  }
  dl div {
    border-bottom: 1px solid $gray2;
    border-bottom-color: rgba($gray2, 0.3);
    padding-bottom: $spacing;
    &:not(.dificultad) {
      display: flex;
      align-items: baseline;
    }
  }
  dt {
    font-size: rem-calc(12);
    text-transform: uppercase;
    font-weight: 900;
    margin-right: $spacing * 0.25;
  }
  dd {
    font-weight: 300;
    font-size: rem-calc(22);
    @media #{$medium-up} {
      font-size: rem-calc(24);
    }
    @media #{$large-up} {
      font-size: rem-calc(26);
    }
  }
  .localizacion {
    dd {
      font-weight: bold;
      font-size: rem-calc(18);
    }
  }
  .precio {
    dd {
      color: $primary-color;
      font-weight: 900;
      text-transform: uppercase;
      font-size: rem-calc(18);
      letter-spacing: 3.75px;
      @media #{$medium-up} {
        font-size: rem-calc(20);
      }
      span {
        display: block;
        font-size: rem-calc(60);
        line-height: 1.1;
        @media #{$medium-up} {
          font-size: rem-calc(70);
        }
        @media #{$large-up} {
          font-size: rem-calc(80);
        }
      }
    }
  }
  svg {
    margin-top: $spacing * 0.75;
    transform: scale(1.45);
    transform-origin: left;
  }
}
