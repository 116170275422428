.newsletter {
  background-color: #aaafb3;
  text-align: center;
  padding: $spacing;
  @media #{$medium-up} {
    margin: $spacing * 3 auto !important;
    background-color: transparent;
  }
  &__container {
    @media #{$medium-up} {
      background-color: #aaafb3;
      padding: $spacing * 2;
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    @media #{$large-up} {
      padding: $spacing * 3;
    }
    > img {
      display: none;
      @media #{$medium-up} {
        display: block;
        margin-top: 0;
        margin-left: $spacing * 2;
      }
    }
    form {
      @media #{$medium-up} {
        margin-top: 0;
        border-left: 1px solid #fff;
        padding-left: $spacing * 2;
        margin-left: $spacing * 2;
      }
      .protecciodades{
        label {text-transform: none;font-weight: normal;}
        a{
          color:#fff;
        }
      }
    }
  }
  * + * {
    margin-top: $spacing * 0.5;
  }
  h2 {
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: rem-calc(20);
    letter-spacing: 3.75px;
    @media #{$medium-up} {
      font-size: rem-calc(30);
    }
  }
  fieldset {
    padding-bottom: 0;
    border: 0;
  }
  .wrapper {
    @media screen and (min-width: 30em) {
      display: flex;
    }
  }
  label {
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: rem-calc(14);
    letter-spacing: 2.63px;
    @media #{$medium-up} {
      text-align: left;
    }
  }
  input[type="email"] {
    border: 0;
    @media screen and (min-width: 30em) {
      flex: 1 1 auto;
    }
    @media #{$medium-up} {
      min-width: 30rem;
    }
  }
  button {
    background-color: transparent;
    @media #{$medium-up} {
      margin-top: 0;
      flex: 0 0 auto;
    }
  }
}
