.intro-img {
  padding: $spacing $column-gutter * 0.5;
  background-color: #444;
  background-size: cover;
  background-position: 50% 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 30vh;
  margin-top: 0;
  margin-bottom: $spacing * 2;
  @media #{$medium-up} {
    min-height: 40vh;
  }
  @media #{$large-up} {
    min-height: 50vh;
  }
  &--btt {
    background-image: url("img/btt_bg.jpg");
    background-position:center center;
  }
  &--road {
    background-image: url("img/road_bg.jpg");
  }
  &--slow {
    background-image: url("img/slow_bg.jpg");
    background-position:center center;
  }
  &--opiniones {
    background-image: url("img/opiniones.jpg");
    background-position:center center;
  }
  &--contacto {
    background-image: url("img/contacto-pedales-del-mundo.jpg");
    background-position:center center;
  }
  &--home {
    //background-image: url("img/pedales-del-mundo-primera-red.jpg");
    background-position: 50% 100%;
    @media #{$large-up} {
      min-height: 735px;

    }
  }
  &__title {
    font-weight: 900;
    font-size: rem-calc(40); /*fallback*/
    font-size: calc(1rem + 4vw);
    line-height: 1.2;
    color: #fff;
    //text-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    text-shadow: 0 0 10px rgba(0,0,0,.5), 0 0 30px rgba(0,0,0,.5), 0 0 50px rgba(0,0,0,.5), 0 0 80px rgba(0,0,0,.5);
    .home & {
      font-size: rem-calc(46);
    }
    span{
      display: block;
      font-size:60%;
    }
  }
  .home & {
    display: none;
    @media #{$medium-up} {
      display: block;
      padding-top: $spacing * 3;
      margin-bottom: 0;
    }
  }
}
