@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"),
    url(/fonts/Lato-Light.woff) format("woff"),
    url(/fonts/Lato-Light.ttf) format("truetype"),
    url(/fonts/Lato-Light.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Lato.eot);
  src: local("Lato"), local("Lato Regular"), local("Lato-Regular"),
    url(/fonts/Lato.eot?#iefix) format("embedded-opentype"),
    url(/fonts/Lato.woff) format("woff"),
    url(/fonts/Lato.svg#Lato) format("svg"),
    url(/fonts/Lato.ttf) format("truetype"),
    url(/fonts/Lato.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: local("Lato Bold"), local("Lato-Bold"),
    url(/fonts/Lato-Bold.woff) format("woff"),
    url(/fonts/Lato-Bold.ttf) format("truetype"),
    url(/fonts/Lato-Bold.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: local("Lato Black"), local("Lato-Black"),
    url(/fonts/Lato-Black.woff) format("woff"),
    url(/fonts/Lato-Black.ttf) format("truetype"),
    url(/fonts/Lato-Black.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"),
    url(/fonts/Lato-Light-Italic.woff) format("woff"),
    url(/fonts/Lato-Light-Italic.ttf) format("truetype"),
    url(/fonts/Lato-Light-Italic.woff2) format("woff2");
  font-display: swap;
}
