.ficha-tecnica {
  h3 {
    text-transform: uppercase;
    font-size: 1rem;
  }
  p {
    font-size: 1rem;
  }
  hr {
    border-top: 3px solid $primary-color;
  }
  table {
    th,
    td {
      padding: $spacing * 0.25 $spacing;
    }
    th {
      padding-left: 0;
    }
    th {
      text-align: left;
      border-bottom: 1px solid #d7d7d7;
      @media #{$medium-up} {
        width: 30%;
      }
    }
    tr:last-child th {
      border-bottom: 0;
    }
  }
  .dest {
    padding-left: 0;
    border-left: 0;
    width: 100%;
    font-size: rem-calc(20);
    font-style: italic;
  }
}
