// https://www.bram.us/2016/05/02/prevent-overscroll-bounce-in-ios-mobilesafari-pure-css/
// html,
// body {
//   position: fixed;
//   overflow: hidden;
// }
// .main-wrapper {
//   width: 100vw;
//   height: 100vh;
//   overflow-y: scroll;
//   -webkit-overflow-scrolling: touch; /* enables “momentum” (smooth) scrolling */
// }
html {
  scroll-behavior: smooth;
}
.ie9 {
  overflow-x: hidden; /* problema scroll horitzontal */
}
@media screen and (-ms-high-contrast: active),
  screen and (-ms-high-contrast: none) {
  /* IE10+ specific styles go here */
  body {
    overflow-x: hidden; /* problema scroll horitzontal */
  }
}

body {
  @media #{$medium-up} {
    &.sticky-header {
      padding-top: rem-calc(153);
    }
  }
}

// Stack (https://every-layout.dev/layouts/stack/)
.stack > * + * {
  margin-top: $spacing;
}
.stack-recursive * + * {
  margin-top: $spacing;
}
.stack-small > * + * {
  margin-top: $spacing * 0.5;
}
.stack-small-recursive * + * {
  margin-top: $spacing * 0.5;
}
.stack-large > * + * {
  margin-top: $spacing * 2;
}
.stack-large-recursive * + * {
  margin-top: $spacing * 2;
}

// text en columnes
.text-2-col {
  @media #{$medium-up} {
    column-count: 2;
    // margin-bottom: $spacing;
  }
}
.text-3-col {
  @media #{$medium-up} {
    column-count: 2;
    // margin-bottom: $spacing;
  }
  @media #{$large-up} {
    column-count: 3;
  }
}
