.search-buttons {
  display: flex;
  margin-top: $spacing * 0.25;
  margin-left: -$spacing * 0.125;
  margin-right: -$spacing * 0.125;
  padding-bottom: $spacing;
  @media #{$medium-up} {
    display: none;
  }
  button {
    // flex-basis: 33.333%;
    flex: 1 1 0;
    margin: 0 $spacing * 0.125;
    background-color: $tertiary-color;
    color: #fff;
    text-transform: uppercase;
    font-weight: 900;
    font-size: rem-calc(12);
    text-align: center;
    letter-spacing: 1px;
    padding: $spacing * 0.5;
    &[aria-expanded="true"] {
      color: #000;
      background-color: $primary-color;
      &:before {
        filter: brightness(0);
      }
    }
    &:before {
      content: "";
      display: block;
      font-size: 1rem;
      margin: 0 auto $spacing * 0.25;
    }
  }
  &__ordena {
    &:before {
      @include sprite("ic_list_24px");
      margin-bottom: 12px !important; /*per alinear textos del botons*/
    }
  }
  &__filtra {
    &:before {
      @include sprite("ico-filtra");
      margin-bottom: 8px !important; /*per alinear textos del botons*/
    }
  }
  &__mapa {
    &:before {
      @include sprite("ic_location_on_24px");
    }
  }
}
