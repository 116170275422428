.h-news-item {
  display: flex;
  flex-direction: column;
  @media #{$medium-up} {
    text-align: left;
  }
  h3 {
    font-weight: bold;
    font-size: rem-calc(24);
    margin-top: $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(27);
    }
    @media #{$large-up} {
      font-size: rem-calc(29);
    }
    a {
      @include hover;
      color: $secondary-color;
    }
  }
  time {
    display: block;
    order: -1;
    font-size: rem-calc(14);
    letter-spacing: 7px;
    @media #{$medium-up} {
      margin-right: auto;
      border-bottom: 1px solid $gray2;
      padding-bottom: $spacing * 0.5;
    }
    &:after {
      content: "";
      display: block;
      width: $spacing * 4;
      height: 1px;
      background-color: $gray2;
      margin: $spacing * 0.5 auto;
      @media #{$medium-up} {
        display: none;
      }
    }
    span {
      @media #{$medium-up} {
        display: block;
        margin-right: -7px; /*valor del letter-spacing*/
      }
    }
  }
  p {
    text-align: left;
    font-size: 1rem;
    margin-top: $spacing * 0.5;
  }
}
