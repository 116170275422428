.main-header {
  position: relative;
  background-color: $tertiary-color;
  margin-top: 0;
  // margin-bottom: $spacing;
  // @media #{$medium-up} {
  //   margin-bottom: 0;
  // }
}
.site-logo {
  line-height: 1;
  font-size: rem-calc(16);
  position: relative;
  z-index: 1;
  font-weight: 300;
  margin: $spacing 0;
  @media #{$medium-up} {
    position: absolute;
    .sticky-header & {
      display: none;
    }
  }
  a {
    color: $body-font-color;
    text-decoration: none;
    display: inline-block;
  }
  img {
    display: block;
    margin-bottom: 0;
    height: rem-calc(63);
    @media #{$medium-up} {
      height: auto;
    }
  }
}

.js .js-hidden{
  display: none;
}
.mobile-social-list{
  li{
    margin-left: $spacing*0.5;
    margin-right: $spacing*0.5;
  }
}



// hack per Safari per evitar aquest problema: https://www.smashingmagazine.com/2018/08/scroll-bouncing-websites/ Seria millor trobar una altra solució però de moment això és el que hi ha.
// _::-webkit-full-page-media,
// _:future,
// :root .main-header.sticky {
//   top: 0;
// }
