// .buttons-container {
//   display: flex;
//   justify-content: center;
//   flex-wrap: wrap;
//   align-items: baseline;
//   .btn {
//     margin: $spacing * 0.5;
//     // margin-right: $spacing * 0.5;
//   }
// }
.btns-mobile {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  @media screen and (min-width: 30.0625em) {
    justify-content: center;
    margin: -$spacing * 0.5;
  }
  @media #{$medium-up} {
    display: none;
  }
  .btn {
    @media screen and (min-width: 30.0625em) {
      // margin-left: $spacing * 0.5;
      margin: $spacing * 0.5;
    }
    @media screen and (max-width: 40em) {
      padding: 0;
      width: rem-calc(82);
      height: rem-calc(60);
    }
    &--miseleccion {
      &:before {
        font-size: 1.25rem;
      }
    }
    &--pdf {
      &:before {
        font-size: 1.25rem;
      }
    }
  }
  + .btn,
  + .btn + .btn {
    width: 100%;
    @media screen and (min-width: 30.0625em) {
      width: auto;
      margin-left: $spacing * 0.5;
      margin-right: $spacing * 0.5;
    }
  }
}
.btns-desktop {
  display: none;
  // @media screen and (min-width: 30.0625em) {
  @media #{$medium-up} {
    display: inline-block;
  }
  .btn {
    margin-left: $spacing * 0.5;
    margin-right: $spacing * 0.5;
  }
  form {
    display: inline-block;
  }
}


#fav-msg{
    position: absolute;
    display: block;
    top: -14px;    
    margin-left: 45px;    
    display: block;
    width: 100px;
    line-height: 1.25;
    font-size: .8em;
    color: #dc982b;
    font-weight: bold;
    @media #{$medium-up} {
      top: -4px;        
    }
}
.ruta{
  #fav-msg{
    top: 14px;
    margin-left: 95px;
    @media #{$medium-up} {
      top: 32px;   
      margin-left: 285px;    
    }
  }
  &.ca{
    #fav-msg{      
      @media #{$medium-up} {        
        margin-left: 335px;    
      }
    }
  }
}