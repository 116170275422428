.houdiniForm {
  fieldset {
    border: 0;
    border-bottom: 1px solid $gray2;
    margin-left: 0;
    margin-right: 0;
    padding: 0 0 $spacing;
    // &:last-child {
    //   border-bottom: 0;
    //   padding-bottom: 0;
    // }
    img{
      margin-top: 0!important;
    }
  }
  legend {
    font-weight: bold;
    text-transform: uppercase;
    font-size: rem-calc(14);
    letter-spacing: 2.63px;
  }
  [class*="column"] > div {
    @extend .stack-small;
    .columns {
      margin-top: 0;
    }
  }
  [class*="column"] {
    @media #{$medium-up} {
      margin-top: 0;
    }
  }
  label,
  span.label {
    display: block;
    font-size: rem-calc(16);
    color: #696969;
    line-height: 1.2;
    &.control {
      text-transform: none;
      font-weight: normal;
      cursor: default;
    }
    &.disabled {
      color: #ccc;
      position: relative;
      &:after{
        content: '*';
        position: absolute;
        top:-5px;
        right: -4px;
      }
    }
  }
  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="password"],
  input[type="search"],
  input[type="number"],
  textarea,
  select {
    color: #000;
    padding: 0.8rem 1rem;
    width: 100%;
    line-height: 1.5;
    border-radius: 4px;
    font-weight: normal;
    border: 1px solid $gray2;

  }
  select.prefix{
    padding: 0.8rem .35rem;
  }
  textarea {
    height: 180px;
  }
  input[type="checkbox"] {
    appearance: checkbox;
    width: $spacing * 0.75;
    height: $spacing * 0.75;
    margin-right: $spacing * 0.125;
    vertical-align: baseline;
  }
  input[type="radio"] {
    appearance: radio;
    width: $spacing * 0.75;
    height: $spacing * 0.75;
    margin-right: $spacing * 0.125;
    vertical-align: baseline;
  }

  // range slider (https://codepen.io/smhigley/pen/ObWbdy)
  // ocultem controls quan hi ha js
  #pres1,
  #pres1 + p {
    .js & {
      display: none;
    }
  }
  // ocultem widget quan no hi ha js
  .custom-slider-widget {
    .no-js & {
      display: none;
    }
  }
  .slider-wrapper {
    // margin: 10px 0 40px;
    max-width: 100%;
    position: relative;
    width: 100%;

    .custom-track {
      background-color: #fff;
      // border: 1px solid $grey;
      border-radius: 5px;
      height: 6px;
      position: relative;
      width: 100%;
      display: block;
      margin-top: $spacing * 2;
    }

    .custom-fill {
      background-color: $primary-color;
      border-radius: 5px;
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      top: 0;
      width: 50%;
    }

    .custom-thumb {
      background-color: $primary-color;
      border-radius: 50%;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      height: 25px;
      left: 50%;
      margin-left: -12.5px;
      margin-top: 0;
      position: absolute;
      top: -10px;
      width: 25px;
      // &:before {
      //   background-color: $light-grey;
      //   border-radius: 50%;
      //   box-shadow: 0 0 3px 0 darken($purple, 30%);
      //   content: "";
      //   height: 12px;
      //   left: 6px;
      //   position: absolute;
      //   top: 6px;
      //   width: 12px;
      // }
    }

    output {
      position: absolute;
      display: block;
      color: #fff;
      font-weight: bold;
      font-size: rem-calc(18);
      bottom: 1.75rem;
    }

    input:focus ~ .custom-track {
      .custom-thumb {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
      }
    }

    input[type="range"] {
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    // make default slider transparent
    // still needs to have user interactions
    input[type="range"]::-ms-track {
      width: 100%;
      height: 100%;
      -webkit-appearance: none;
      margin: 0px;
      padding: 0px;
      border: 0 none;
      background: transparent;
      color: transparent;
      overflow: visible;
    }

    input[type="range"]::-moz-range-track {
      width: 100%;
      height: 100%;
      -moz-appearance: none;
      margin: 0px;
      padding: 0px;
      border: 0 none;
      background: transparent;
      color: transparent;
      overflow: visible;
    }

    input[type="range"] {
      width: 100%;
      height: 100%;
      -webkit-appearance: none;
      margin: 0px;
      padding: 0px;
      border: 0 none;
      background: transparent;
      color: transparent;
      overflow: visible;
    }

    input[type="range"]:focus::-webkit-slider-runnable-track {
      background: transparent;
      border: transparent;
    }

    input[type="range"]:focus {
      outline: none;
    }

    // thumb needs to be same size as custom thumb
    input[type="range"]::-ms-thumb {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border: 0 none;
      background: transparent;
      // cursor: pointer;
    }

    input[type="range"]::-moz-range-thumb {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border: 0 none;
      background: transparent;
      // cursor: pointer;
    }

    input[type="range"]::-webkit-slider-thumb {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border: 0 none;
      background: transparent;
      // cursor: pointer;
      -webkit-appearance: none;
    }

    // microsoft IE10+ and Edge - eliminates default fills before and after thumb
    input[type="range"]::-ms-fill-lower {
      background: transparent;
      border: 0 none;
    }

    input[type="range"]::-ms-fill-upper {
      background: transparent;
      border: 0 none;
    }

    input[type="range"]::-ms-tooltip {
      display: none;
    }
  }
  .values {
    div {
      color: #fff;
      letter-spacing: 1px;
      font-size: rem-calc(16);
      width: 50%;
      margin-top: 0;
    }
  }
  .value-1 {
    float: left;
  }
  .value-2 {
    float: right;
    text-align: right;
  }

  // wtf-forms overwrites
  .select {
    width: 100%;
    font-weight: normal;
    &:after {
      color: #000;
    }
    &:before {
      display: none;
    }
    select {
      // border: 1px solid $gray2;
      // border-radius: 4px;
      // padding-top: 0.8rem;
      padding-bottom: 0.67rem;
      background-color: #f0f0f0;
      background-image: linear-gradient(to bottom, #ffffff, #d7d7d7);
      &:focus {
        box-shadow: 0 0 0 0.2rem $focus-color;
      }
      &:active {
        color: inherit;
        background-color: #efefef;
      }
    }
  }
  .control input:focus ~ .control-indicator {
    box-shadow: 0 0 0 0.2rem $focus-color;
  }
  .control input:checked ~ .control-indicator {
    background-color: $secondary-color;
    .forms-container & {
      background-color: $primary-color;
    }
  }
  .checkbox .control-indicator {
    border-radius: 0;
    margin-top: 0;
    background-color: $gray1;
  }
  .radio .control-indicator {
    margin-top: 0;
    background-color: $gray1;
  }
}

// Filtres rutes
.forms-container {
  background-color: $secondary-color;
  padding: $spacing $spacing 0;
  position: relative;
  margin-left: -$column-gutter * 0.5;
  margin-right: -$column-gutter * 0.5;
  margin-bottom: $spacing * 1.5;
  color: #fff;
  @media #{$medium-up} {
    padding: $spacing * 2 $spacing;
    margin-left: 0;
    margin-right: 0;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: $spacing * 3;
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0));
    opacity: 0.5;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: -$spacing * 3;
    @media #{$medium-up} {
      display: none;
    }
  }
  form {
    position: relative;
  }
  fieldset {
    @extend .stack-small;
    border-bottom-color: #fff;
    border-bottom-color: rgba(#fff, 0.2);
  }
  legend {
    color: #fff;
  }
  .search-container {
    display: flex;
    input {
      flex: 1 1 auto;
    }
    button {
      background-color: $primary-color;
      margin-top: 0;
      min-width: 50px;
      text-align: center;
    }
  }
  &__section {
    background-color: $tertiary-color;
    padding: $spacing;
    margin-top: 0;
    margin-left: -$spacing;
    margin-right: -$spacing;
    @media #{$medium-up} {
      background-color: inherit;
    }
    &--ordena,
    &--mapa {
      @media #{$medium-up} {
        display: none;
      }
    }
    &--filters {
      @media #{$medium-up} {
        display: block !important;
      }
    }
  }
  input[type="search"],
  input[type="text"],
  select {
    border-radius: 0;
    border: 0;
  }
  ul.ordena-list {
    @include undolist;
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    font-size: 1rem;
    letter-spacing: 3px;
    li {
      border-bottom: solid 1px #ffffff;
      border-bottom-color: rgba(#fff, 0.2);
      padding-bottom: $spacing * 0.5;
      &:last-child {
        border: 0;
        padding-bottom: 0;
      }
      &.active a {
        color: $primary-color;
      }
    }
    a {
      // display: block;
      @include hover;
      color: #ffffff;
    }
  }
  svg {
    margin: 0 $spacing * 0.125 0 0 !important;
  }
  .icon-off {
    opacity: 0.1;
  }
}
.search-form {
  @media screen and (max-width: $medium-breakpoint) {
    fieldset {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}
.checks-list {
  @include undolist;
  .control > * {
    margin-top: 0 !important;
    vertical-align: middle;
  }
  .control-indicator {
    margin-top: -2px !important;
  }
  label {
    color: #fff;
    font-size: rem-calc(14);
  }
  .num {
    color: $primary-color;
  }
}
.inputs-list {
  @include undolist;
  padding-top: $spacing * 0.5;
  &.b{
    margin-top: 0;
  }
  li {
    display: inline;
    margin-right: $spacing * 0.5;
    padding-right: $spacing * 0.5;
    border-right: 1px solid $gray1;
    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: 0;
    }
  }
  label {
    display: inline;
  }
}
.more-inputs{
  background: lighten($secondary-color,70%);
  padding: .75rem;
  position: relative;
  label{
    color: #000;
    font-size:rem-calc(14);
  }
  &:before{
    content: '';
    position: absolute;
    top:-10px;
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent lighten($secondary-color,70%) transparent;
  }
  .select{
    margin-top: .75rem;
  }
}
// cercador home
.h-search {
  background-color: #000;
  padding: $spacing $mobile-padding;
  @media screen and (min-width: 50em) {
    background-color: rgba(#000, 0.5);
    position: absolute;
    bottom: $spacing * 4;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: $row-width;
    z-index: 2;
  }
  fieldset {
    border: 0;
    padding: 0;
    .container {
      > * + * {
        margin-top: $spacing * 0.5;
      }
      @media screen and (min-width: 50em) {
        display: flex;
        align-items: center;
      }
      > * {
        @media screen and (min-width: 50em) {
          margin-top: 0;
          margin-left: $spacing * 0.5;
          margin-right: $spacing * 0.5;
        }
      }
      > div {
        flex-basis: 25%;
      }
    }
  }
  .select {
    margin-top: 0;
  }
  select,
  input::placeholder {
    text-transform: uppercase;
    font-weight: 900;
    color: #000;
    font-size: rem-calc(14);
    opacity: 1;
  }
  option {
    font-weight: normal;
  }
  select,
  input {
    border: 0 !important;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }
  button {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    background-color: transparent;
    display: block;
    @media screen and (min-width: 50em) {
      margin-left: $spacing * 0.5;
      margin-right: $spacing * 0.5;
    }
  }
  p {
    text-align: center;
    font-size: rem-calc(14);
    @media screen and (min-width: 50em) {
      text-align: left;
    }
    a {
      color: #fff;
      @include hover;
    }
  }
}
//select dificultat home
.custom-select{
  position: relative;
  .select{
    &.newSelect{
      padding: .8rem 1rem;
      //top:0;
      width: 100%;
      line-height: 1.5;
      border-radius: 4px;
      //padding-bottom: .67rem;
      background: #fff;
      text-transform: uppercase;
      font-weight: 900;
      color: #000;
      //font-size: .875rem;
      cursor: pointer;
      margin:0;
      //position: absolute;
      //z-index: 3;
      &[aria-expanded="true"]{
        &:after{
          content: 'x';
          border: none;
          color: #fff;
          font-weight: 100;
          font-size: rem-calc(12);
          right: 1rem;
          margin-top: -10px;
          background: $primary-color;
          width: 20px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 50%;
        }
      }
    }
  }
  .select-options{
    background: #fff;
    //border: solid 1px $primary-color;
    box-shadow: 0 2px 2px rgba(0, 0, 0, .24), 0 0 2px rgba(0, 0, 0, .12);
     border-radius: 4px;
    position: absolute;
    @include undolist;
    margin-top: 14px;
    width:100%;
    z-index:2;
    padding: 1em 0;
    &:before {
        content: ' ';
        position: absolute;
        top: 0;
        margin-top: -15px;
        left: 5%;
        width: 0;
        height: 0;
        border: 9px solid rgba(255,255,255,0);
        border-bottom-color: #fff;
        z-index: 5;
    }
    span{
      display: none;
      &.title{
        display: block;
        text-transform: uppercase;
        font-weight: 900;
        color: #000;
        font-size: .875rem;
        line-height: 35px;
      }
    }

    &.select-options-routes{
      @media #{$medium-up} {
        width:500px;
      }
      li{
        margin-bottom: 1rem;
        @media #{$medium-up} {
          margin-bottom: .75rem;
        }
        &.foto{
          background-size: 75px;
          background-repeat: no-repeat;
          background-position: 7px center;
          @media #{$medium-up} {
            background-size:150px;
            min-height: 101px;
          }
        }
      }
      span{
        padding-left: 75px;
        @media #{$medium-up} {
            padding-left:150px;
        }
        &.title{
          line-height:1.4;
          margin-top: -.25em;
          @media #{$medium-up} {
            padding-top: .5rem;
            margin-top: 0;
          }
        }
        &.description{
          display: block;
          color: #414141;
          font-size: .75rem;
          line-height: 1.3;
          margin-top: .2rem;
          @media #{$medium-up} {
            padding-bottom: .5rem;
            font-size: .875rem;
          }
        }
      }
    }
    li{
      cursor: pointer;
      padding:.25em 1.5em;
      margin: 0;
      &:hover{
        background-color: #efefef;
      }
      &.selected{
        //opacity: .65;
        cursor: default;
        background-color: rgba($primary-color,.1);
      }
      &.full{
        text-align: center;
        span.title{
          padding: 0;
        }
      }
      svg{
        width: 200px;
        height: 22px;
      }
    }
    .foto-btt-mini {
      background-image: url("img/btt-select.jpg");
    }
    .foto-slow-mini {
      background-image: url("img/slow-select.jpg");
    }
    .foto-road-mini {
      background-image: url("img/road-select.jpg");
    }
  }
}
.newSelect{
  display: none;
}
.js{
  .custom-select .select{
    display: none;
  }
  .custom-select .select.newSelect{
    display: inline-block;
  }
}
.newSelect{
  text-align: left;
  svg{
    max-width: 145px;
    position: absolute;
    right: 3em;
    margin-top: 3px;
  }
}

.houdiniForm .protecciodades{
  //padding: 0 .5rem;
  position: relative;
  input{
    position: absolute;
    top:2px;
    margin: 0;
    left: 0;
    width: auto;
    height: auto;
  }
  label{
    padding-left: 1.25em;
    position: relative;
  }
}

.reinit{
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  left:0;
   background-color: #222e39;
  @media #{$medium-up} {
     margin-top: -$spacing * 1.5;
     position: relative;
  }
  .btn{
    border-radius: 0;
    text-decoration: underline;
    text-transform: none;
    font-size: rem-calc(13);
    margin: 0 auto;
    width: auto;
    @media #{$medium-up} {
      width: 100%;
      font-size: rem-calc(16);
    }
    cursor: pointer;
    font-weight: 600;
    letter-spacing: initial;
    background-color: #222e39;
    border-color: #222e39;
    @include single-transition();
    svg{
      position: absolute;
      margin-left: -1em;
      margin-top: .25em;
      @media #{$medium-up} {
        margin-left: 1em;
      }
    }
    &:hover{
      background-color: #000;
      border-color: #000;
      color:#fff;
    }
  }
}

.section{
  form{
    padding:$spacing;
    @media #{$medium-up} {
      padding:0 $spacing*3;
    }
    &.ajax{
      padding:0;
      margin:0;
    }
    label{
      color:#fff;
    }
    text-align: left;
  }
}
form.ajax{
  padding:0;
  margin:0;
  .results__item & {
    line-height: 0;
  }
}
#presupuesto{
  margin-top: $spacing*1.5;
  @media #{$medium-up} {
      margin-top: 0;
    }
}
input#fecha{
    background-image: url('img/calendar.svg');
    background-repeat: no-repeat;
    background-position: 96% 50%;
}
.select2{
  ul{
    @include undolist;
    li{
      margin: 0;
    }
  }
}
label.error{
  color:#D32F2F;
  margin-top: 6px!important;
  font-size:rem-calc(12);
}
input.error + span.control-indicator{
  border:solid 1px #D32F2F;
}