.news-head {
  display: flex;
  flex-direction: column;
  > * {
    @media #{$large-up} {
      max-width: 80%;
    }
  }
  &__title {
    font-family: Piedra, sans-serif;
    font-weight: normal;
    line-height: 1.2;
    font-size: rem-calc(30);
    margin-top: $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(35);
    }
    @media #{$large-up} {
      font-size: rem-calc(42);
    }
  }
  &__date {
    order: -1;
    font-weight: 300;
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
  }
  &__intro {
    font-weight: 300;
    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
  }
  &__cat {
    order: -2;
    margin-top: 0;
    margin-right: auto;
    font-family: Piedra, sans-serif;
    font-weight: normal;
    font-size: rem-calc(18);
    background-color: $quaternary-color;
    padding: $spacing * 0.25 $spacing * 0.5;
    @media #{$medium-up} {
      font-size: rem-calc(20);
    }
    a {
      color: $tertiary-color;
      @include hover;
    }
  }
}
.news-body {
  @media #{$large-up} {
    > *:not(img) {
      max-width: 80%;
    }
  }
}
