.opinio-container {
  background-color: $gray1;
  padding: $spacing;
  @media #{$large-up} {
    padding: $spacing * 1.5 $spacing * 2;
  }
  h2 {
    text-transform: uppercase;
    text-align: center;
    font-weight: 900;
    font-size: rem-calc(20);
    letter-spacing: 3.75px;
  }
}
.opinio {
  text-align: center;
  a{
    color: $body-font-color;
    text-decoration: none;
    display: block;
  }
  &__title {
    font-size: 1rem;
    font-weight: bold;
    span{
      font-weight: normal;
      color:#414141;
    }
  }
  blockquote {
    font-weight: 300;
    font-style: italic;
    font-size: rem-calc(26);
  }
  time {
    display: block;
    font-size: rem-calc(14);
    letter-spacing: 7px;
  }
}

.star-on {
  fill: #b28a54;
}
.opinio-detall{
  background: #fff;
    border-bottom: 1px solid #ddd;
    padding-bottom: $spacing;

    @media #{$medium-up} {
    	padding: $spacing*2;
    	border: 1px solid #ddd;
    	border-radius: 8px;
  	}
    h1{
      color: $secondary-color;
      line-height: 1.3;
    }
    .autor{
      margin-top: .5rem;
      font-size: rem-calc(14);
      border-bottom:1px solid #ddd;
      padding-bottom: $spacing;
      margin-bottom: $spacing;

      line-height: 20px;
      @media #{$medium-up} {
    	display: flex;
      	align-items: center;
      	flex-wrap: wrap;
      	font-size: rem-calc(18);
  	  }
      svg{
        display: block;
        margin-bottom: $spacing*0.5;
        @media #{$medium-up} {
	    	display: flex;
        	align-items: center;
        	margin-right: $spacing;
        	margin-bottom: 0;
	  	}
      }
    }
    .infos{
      padding-bottom: $spacing;
      margin-bottom: $spacing;
      border-bottom:1px solid #ddd;
      h2{
        font-size: rem-calc(18);
        font-weight: normal;
        a{
          color:$body-font-color;
        }
      }
    }
    .message{
    	padding: 0;
      	p{
        	font-size: rem-calc(18);
      	}
    }
}
.btn-list{
  @include undolist;
  margin-top: $spacing;
  li{
    margin: $spacing 0;
  }
  .btn{
    text-transform: none;
  }
}
.opiniones-list{
  @include undolist;
  display: flex;
  flex-wrap: wrap;
  padding: $spacing 0;
  margin-top: 0;
  margin-left:-$spacing;
  margin-right:-$spacing;
  li{

    padding: 0 $spacing;
    margin: 0 0 $spacing;
    @media only screen and (min-width: 40em){
      width: 50%;
    }
    @media #{$medium-up} {
      width: 33.33%;
    }
    .opinio{
      text-align: left;
      height: 100%;
      width: 100%;
      a{
        color: $body-font-color;
        text-decoration: none;
        display: inline-block;
        padding: $spacing;
        //border:1px solid #ddd;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.22);
        //border-radius: 8px;
        width: 100%;
        height: 100%;
        @include single-transition();
        h2{
          color: $primary-color;
          font-size: rem-calc(22);
        }
        h3,blockquote{
          font-size: rem-calc(16);
        }
        &:hover{
          background: #f6f6f6;
        }
      }
      .info{
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        h3{
          max-width: 67%;
        }
        svg{
          display: flex;
          margin-top: 0;
          max-width: 33%;
        }
      }
      time{
        display: inline-block;
        letter-spacing: normal;

      }
    }
  }
}