// .nav {
//   .js & {
//     position: absolute;
//     z-index: 2;
//     top: 0;
//     left: 0;
//     right: 0;
//     @media #{$medium-up} {
//       position: static;
//     }
//   }
// }
// .js-menu-open .nav {
//   z-index: 3;
// }
.menu-button {
  display: block;
  position: absolute;
  top: $spacing;
  right: $mobile-padding*0.5;
  cursor: default;
  z-index: 1;
  //padding: $spacing*0.5;
  line-height: 1;
  width: 40px;
  height: 40px;
  text-align: center;
  @media #{$medium-up} {
    display: none;
  }
  &:focus{
    outline: 0;
    border: solid 1px rgba(255,255,255,.23);
  }
}
.menu-wrapper {
  padding: 0 $spacing $spacing;
  color: #fff;
  background-color: $tertiary-color;
  @media #{$medium-up} {
    padding: 0;
    margin-top: 0 !important;
    background-color: transparent;
  }
  a {
    color: #fff;
    text-decoration: none;
    transition: border-bottom-color 0.15s ease-in-out;
    &:hover,
    &:focus {
      text-decoration: underline;
      @media #{$medium-up} {
        text-decoration: none;
        border-bottom-color: #fff;
      }
    }
    &[aria-current="page"] {
      @media #{$medium-up} {
        border-bottom-color: #fff;
      }
    }
  }
}
.site-menu {
  list-style: none;
  @media #{$medium-up} {
    clear: both;
    text-align: center;
    margin: 0 !important;
    padding-top: 3rem;
    padding-bottom: $spacing;
    transition: padding-bottom 0.15s ease-in-out;
    .sticky-header & {
      padding-bottom: $spacing * 0.5;
    }
  }
  > li {
    @media #{$medium-up} {
      display: inline-block;
    }
    &:before {
      content: "";
      display: block;
      margin-top: $spacing * 0.75;
      margin-bottom: $spacing * 0.25;
      font-size: 1rem;
      @media #{$medium-up} {
        display: none !important;
      }
    }
    &:nth-child(1) {
      &:before {
        @include sprite("ico-peu-btt");
      }
    }
    &:nth-child(2) {
      &:before {
        @include sprite("ico-peu-slow");
      }
    }
    &:nth-child(3) {
      &:before {
        @include sprite("ico-peu-road");
      }
    }
    &.has-submenu {
      @media #{$medium-up} {
        position: relative;
        &:after {
          content: "";
          display: block;
          font-size: 1rem;
          position: absolute;
          right: 0.75rem;
          top: 50%;
          transform: translateY(-50%);
          @include sprite("ico-fletxa-menu-desplega-copy");
        }
        &:hover,
        &:focus-within {
          > a {
            border-bottom: 2px solid #fff;
            border-bottom-color: rgba(#fff, 0.8);
          }
          ul {
            display: block;
            position: absolute;
            margin-top: 0;
            padding: $spacing * 0.5;
            text-align: left;
            text-transform: uppercase;
            font-size: rem-calc(12);
            background-color: $secondary-color;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
            left: $spacing * 1.5;
            right: rem-calc(12);
            border-top: $spacing $tertiary-color solid;
            border-bottom: 0;
            z-index: 2;
            .sticky-header & {
              border-top-width: $spacing * 0.5;
            }
            // &:before {
            //   content: "";
            //   @include triangle($spacing * 0.25, #fff, bottom);
            //   position: absolute;
            //   top: -11px;
            //   left: 50%;
            //   transform: translateX(-50%);
            // }
            li {
              margin-top: 0;
              &:first-child {
                a {
                  padding-top: 0;
                }
              }
              &:last-child {
                a {
                  padding-bottom: 0;
                  border-bottom: 0;
                }
              }
            }
            a {
              display: block;
              color: #fff;
              letter-spacing: 2.25px;
              border-bottom: 1px solid rgba(white, 0.1);
              padding: $spacing * 0.5 0;
              &:hover,
              &:focus {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    > a {
      font-family: Piedra, $body-font-family;
      font-size: rem-calc(24);
      text-transform: uppercase;
      display: block;
      @media #{$medium-up} {
        font-family: $body-font-family;
        font-size: rem-calc(14);
        font-weight: 900;
        display: inline-block;
        letter-spacing: 2.6px;
        margin: 0 $spacing * 1.5;
        border-bottom: 2px solid transparent;
        &:hover,
        &:focus {
          text-decoration: none;
          border-bottom: 2px solid #fff;
          border-bottom-color: rgba(#fff, 0.8);
        }
      }
      span {
        display: inline-block;
        margin-right: -2.6px;
      }
    }
  }

  ul {
    border-bottom: 1px solid #fff;
    padding-bottom: $spacing;
    @media #{$medium-up} {
      display: none;
    }
  }
}
