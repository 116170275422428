#paquetes, #etapas{
  .message{
    p{
      background-color: #ccc;
      padding: .5rem;
      span{
        text-transform: capitalize;
        font-weight: bold;
      }
      sup{
        font-size: rem-calc(16);
        top: -.1em;
      }
    }
  }
}

.detall{
  border:solid 1px lighten($primary-color,40%);
  h2{
    background: lighten($primary-color,40%);
     padding:.35rem 1rem;
     font-size: 1em;
  }
  #detall-reserva{
    padding-bottom:1.5rem;
    a{
      margin:0 1.5rem;
      font-size: rem-calc(12);
    }
    ul{
      @include undolist;
      padding: 0 1rem;
      li{
        font-weight: 700;
        font-size: rem-calc(13);
        margin-top: 0;
        span{
          display: block;
          font-weight: 400;
        }
        &.grupo{
          div{
            display: block;
          }
          span{
            display: inline;
          }
        }
      }
    }

  }
}
#detall-precio{
  position: relative;

  .resum-preu{
    margin: 0;
    padding:1.5rem;
    width:60%;
    min-height: 117px;
    font-size:rem-calc(14);
    border-right: solid 1px lighten($primary-color,40%);
    ul{
      @include undolist;
      li{
        margin-top:.35rem;
        span{
          float: right;
          margin: 0;
        }
      }
    }
  }
  .preu{

    font-size:rem-calc(20);
     padding:1.5rem;
    width: 40%;
    position: absolute;
    right: 0;
    top:0;
    span{
      display: block;
      font-size:rem-calc(26);
      font-weight: 700;
    }
  }
}
.nota{
  margin-top: .75rem;
  //padding: 0 .5rem;
  font-size: rem-calc(12);
}