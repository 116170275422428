.slider-container {
  position: relative;
  background-color: $gray2;
  @media #{$large-up} {
    margin-bottom: $spacing * 3;
  }
  .wrapper {
    height: $slider-h-sm;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    @media #{$xlarge-up} {
      height: $slider-h-xlg;
    }
    > img {
      object-fit: cover;
      height: 100%;
      width: 100%;
      @media screen and (min-width: 30em) {
        display: none;
      }
    }
  }
  h2 {
    text-align: center;
    color: #fff;
    font-family: Piedra, sans-serif;
    text-transform: uppercase;
    text-shadow: 0 8px 14px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: $spacing;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    font-size: rem-calc(30);
    font-weight: normal;
    line-height: 1.2;
    width: calc(100% - 3rem);
    @media #{$medium-up} {
      font-size: rem-calc(50);
      //top: $spacing * 2;
       top: 50%;
      margin-top: -7rem;
    }
    @media #{$large-up} {
      font-size: rem-calc(60);
      //top: $spacing * 3;
      //top: 50%;
      //margin-top: -7rem;
    }
    a {
      @include hover;
      color: #fff;
    }
  }
}

// Slider home
.slider {
  display: none;
  @media screen and (min-width: 30em) {
    display: block;
  }
  opacity: 0;
  .no-js & {
    opacity: 1;
    .slider__img{
      display: none!important;
    }
    .slider__img.slider__img--1{
      display: block!important;
    }
  }
  &.slick-initialized{
    opacity: 1;
  }
  .slick-slide > div {
    height: $slider-h-sm;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    @media #{$xlarge-up} {
      height: $slider-h-xlg;
    }
  }
  &__img {
    background-size: cover;
    background-repeat: no-repeat;
    height: $slider-h-sm;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    position: relative;
    @media #{$medium-up} {
      height: $slider-h-md;
    }
    @media #{$height} {
      height: $slider-h-lg;
    }
    @media #{$xlarge-up} {
      height: $slider-h-xlg;
    }
    background-position: 50% 100%;
    &:before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }
  }
  &__image {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .slick-dots {
    @include undolist;
    bottom: $spacing;
    li {
      margin: 0 $spacing * 0.5 0 0;
      position: relative;
      top: -5px;
      button {
        &:before {
          font-size: 12px;
          opacity: 0.5;
        }
      }
      &.slick-active button::before {
        color: #fff;
        opacity: 1;
      }
    }
  }
}

.slider-2 {
  opacity: 0;
  .no-js & {
    opacity: 1;
    .box{
      max-width:300px;
      margin-bottom: 1rem;
      @media #{$medium-up} {
        width: 33%;
        max-width:none;
        border: solid 10px $secondary-color;
        float: left;
        display: inline-block;
        &:nth-of-type(3n+1) {
            clear: both;
        }
      }
    }
  }
  &.slick-initialized{
    opacity: 1;
  }
  .slick-track {
    display: flex;
  }
  .slick-slide {
    margin-left: $spacing;
    margin-right: $spacing;
    height: auto;
    > div {
      height: 100%;
    }
  }
  .slick-arrow {
    width: 20px;
    height: 34px;
    &:before {
      content: "";
      font-size: 1rem;
    }
  }
  .slick-prev {
    left: -10px;
    z-index: 1;
    @media #{$medium-up} {
      left: -15px;
    }
    @media #{$large-up} {
      left: -20px;
    }
    // @media #{$xlarge-up} {
    //   left: -25px;
    // }
    &:before {
      @include sprite("fletxa-slider-anterior");
    }
  }
  .slick-next {
    right: -10px;
    @media #{$medium-up} {
      right: -15px;
    }
    @media #{$large-up} {
      right: -20px;
    }
    // @media #{$xlarge-up} {
    //   right: -25px;
    // }
    &:before {
      @include sprite("fletxa-slider-seguent");
    }
  }
}
