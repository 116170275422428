@font-face {
  font-family: "Piedra";
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/Piedra-Regular.eot);
  src: local("Piedra Regular"), local("Piedra-Regular"), local("Piedra"),
    url(/fonts/Piedra-Regular.woff) format("woff"),
    url(/fonts/Piedra-Regular.svg#Piedra) format("svg"),
    url(/fonts/Piedra-Regular.woff2) format("woff2"),
    url(/fonts/Piedra-Regular.eot?#iefix) format("embedded-opentype"),
    url(/fonts/Piedra-Regular.ttf) format("truetype");
  font-display: swap;
}
