// box-list
.box-list {
  @include undolist;
  @include block-grid(1);
  @media screen and (min-width: 30em) {
    @include block-grid(2);
  }
  @media screen and (min-width: 40em) {
    @include block-grid(3);

  }
  @media screen and (min-width: 66em) {
    &.b{
        @include block-grid(4);
        li .box h3{
          min-height: 108px;
        }
      }
  }

  li {
    margin-top: 0;
  }
}
.main-content .box {
  position: relative;
  &--neg {
    .box__title {
      background-color: #fff;
      color: $secondary-color;
      a {
        color: $secondary-color;
      }
    }
  }
  &__title {
    padding: $spacing;
    background-color: $secondary-color;
    margin-top: 0;
    font-weight: 300;
    font-size: rem-calc(26);
    line-height: 1.1;
    @media #{$medium-up} {
      font-size: rem-calc(34);
    }
    @media #{$large-up} {
      font-size: rem-calc(38);
    }
    &.b{
      font-size: rem-calc(20);
      @media #{$medium-up} {
        font-size: rem-calc(24);
      }
      @media #{$large-up} {
        font-size: rem-calc(28);
      }
    }
    strong {
      font-weight: 900;
      display: block;
    }
  }
  a {
    @include hover;
    color: #fff;
    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}
.box__wrapper {
  padding: 0 $spacing * 0.5 $spacing;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.box--2 {
  background-color: #fff;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  * + * {
    margin-top: $spacing * 0.5;
    @media #{$medium-up} {
      margin-top: $spacing;
    }
    // @media #{$large-up} {
    //   margin-top: $spacing * 1.5;
    // }
  }
  padding-bottom:  $spacing * 4;
  .box__title {
    text-transform: none;
    letter-spacing: 0;
    padding: 0;
    font-size: rem-calc(26);
    @media #{$medium-up} {
      font-size: rem-calc(34);
    }
    @media #{$large-up} {
      font-size: rem-calc(38);
    }
  }
  a:after {
    display: none !important;
  }
  p {
    color: $body-font-color;
    padding-bottom: $spacing * 0.5;
    @media #{$medium-up} {
      padding-bottom: $spacing;
    }
  }
  .btn {
    padding-left: $spacing * 0.5;
    padding-right: $spacing * 0.5;
    //margin: auto auto 0;
    position: absolute;
    bottom: $spacing * 2;
    left: 50%;
    transform: translateX(-50%);
    @media #{$medium-up} {
      padding-left: $spacing;
      padding-right: $spacing;
    }
    @media #{$large-up} {
      padding-left: $spacing * 1.5;
      padding-right: $spacing * 1.5;
    }
    &:hover {
      color: $primary-color;
      text-decoration: none !important;
    }
  }
  &.box--clickable{
    &.on{
      cursor: pointer;
      @include single-transition();
      .box__title {
          background-color: transparent;
      }
      &:hover{
        background-color: #f7f7f7;
        .box__title a {
          text-decoration: none;
        }
      }
    }
  }
}
@media #{$small-only} {
  .isMobile{
    .mobile-carousel{
      padding: 0;
      overflow: hidden;
      h2{
        padding: 0 $spacing;
      }

      .box-list{
          margin-right: 0;
          margin-left: 0;
          //width: 100%;
          -webkit-overflow-scrolling: touch;
           display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          li{
            flex: 0 0 auto;
            margin-right: 3px;
            width: 250px;
            min-height: 345px;
            &:first-child{
              margin-left: $spacing*0.5;
            }
            &:last-child{
              margin-right: $spacing*0.5;
            }
          }
      }
    }
  }
}

