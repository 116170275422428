.ordena {
  display: none;
  @media #{$medium-up} {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    border-bottom: 1px solid $gray2;
    padding-bottom: $spacing;
    position: relative;
    .count-result{
      position:absolute;
      left: 0;
      top: -2px;
    }
  }
  p {
    font-size: rem-calc(12);
    &:before {
      content: "";
      @include sprite("ic_list_24px");
      filter: brightness(0);
      font-size: 1rem;
      margin-right: $spacing * 0.25;
      position: absolute;
      top: 4px;
      margin-left: -25px;
    }
  }
  ul {
    @include undolist;
    display: flex;
    text-transform: uppercase;
    font-weight: 900;
    font-size: rem-calc(12);
    letter-spacing: 2.25px;
    li {
      display: inline-block; /*no flexbox fallback*/
      margin-top: 0;
      &.active a {
        color: #fff;
        background-color: $primary-color;
      }
    }
    a {
      text-decoration: none;
      padding: $spacing * 0.25 $spacing * 0.5;
      margin-left: $spacing * 0.5;
      &:hover {
        color: #fff;
        background-color: $primary-color;
      }
      &:after{
        padding-left: 1px;
        font-size: 8px;
        position: absolute;
      }
      &.asc{
        &:after{
          content: "\25B2";
          top: 2px;
        }
      }
      &.desc{
        &:after{
          content: "\25BC";
          bottom: 2px;
        }
      }
    }
  }
}
.ordena-list{
  li{
    a{
      position: relative;
      &:after{
        padding-left: 1px;
        font-size: 8px;
        position: absolute;
      }
      &.asc{
        &:after{
          content: "\25B2";
          top: 2px;
        }
      }
      &.desc{
        &:after{
          content: "\25BC";
          bottom: 2px;
        }
      }
    }
  }
}
@media #{$medium-up} {
  .js .send-filters{
    display: none;
  }
}