.grid-list {
  margin-left: -$spacing * 0.25;
  margin-right: -$spacing * 0.25;
  display: flex;
  flex-wrap: wrap;
  font-size: rem-calc(18);
  @media #{$medium-up} {
    font-size: rem-calc(20);
  }
  li {
    margin: 0 !important;
    display: flex;
    padding: $spacing * 0.25;
    width: 100%;
    // no flexbox fallback
    .ie9 & {
      display: inline-block;
      vertical-align: top;
    }
    &:before {
      display: none;
    }
    &:nth-child(1) a,
    &:nth-child(2) a {
      transition: background-color 0.15s ease-in-out;
    }
    &:nth-child(1) a {
      background-color: $secondary-color;
      &:hover {
        background-color: darken($secondary-color, 2%);
      }
    }
    &:nth-child(2) a {
      color: #000;
      background-color: $quaternary-color;
      &:hover {
        background-color: lighten($quaternary-color, 2%);
      }
    }
    &:nth-child(3) a,
    &:nth-child(4) a {
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      transition: background-color 0.15s ease-in-out;
      &:before {
        content: "";
        display: block;
        background-color: #000;
        background-color: rgba(#000, 0.6);
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        transition: background-color 0.15s ease-in-out;
      }
      &:hover {
        &:before {
          background-color: rgba(#000, 0.7);
        }
      }
      span {
        position: relative;
        z-index: 1;
      }
    }
    &:nth-child(3) a {
      background-image: url("img/bg-btn-club.jpg");
    }
    &:nth-child(4) a {
      background-image: url("img/bg-btn-tienda.jpg");
    }
    @media screen and (min-width: 30em) {
      width: 50%;
    }
    // @media #{$medium-up} {
    //   width: 33.33%;
    // }
    // @media #{$large-up} {
    //   width: 25%;
    // }
  }
  a {
    text-decoration: none;
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    padding: $spacing * 2.5 $spacing;
    width: 100%;
    position: relative;
    line-height: 1.3;
    letter-spacing: 3.75px;
    font-weight: 900;
    background-color: #333;

    // no flexbox fallback
    .ie9 & {
      display: block;
    }
    &:hover,
    &:focus {
      background-color: #272727;
      text-decoration: underline;
    }
  }
}
